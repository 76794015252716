import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@routes/Home.vue";
import { hasPagePermissions } from "./models/permissions";
import { Cookies } from "./network";
import session from "./session";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { public: true }
  },
  // Login
  {
    path: "/login",
    name: "Login",
    component: () => import("@routes/Login.vue"),
    meta: { public: true }
  },
  // Register User
  {
    path: "/register",
    name: "RegisterUser",
    component: () => import("@routes/RegisterUser.vue"),
    meta: { public: true }
  },
  // Password Reset
  {
    path: "/passwords/reset",
    name: "ResetPassword",
    component: () => import("@routes/ResetPassword.vue"),
    meta: { public: true }
  },
  // Users
  {
    path: "/users",
    name: "Users",
    component: () => import("@routes/Users.vue"),

    children: [
      {
        path: "list",
        name: "ListUsers",
        component: () => import("@components/UsersList.vue")
      },
      {
        path: "create",
        name: "CreateUser",
        component: () => import("@components/UserForm.vue")
      },
      {
        path: ":userId",
        name: "EditUser",
        component: () => import("@components/UserForm.vue")
      }
    ]
  },
  // One on One
  {
    path: "/one-on-one",
    name: "OneOnOne",
    component: () => import("@routes/OneOnOne.vue"),

    children: [
      {
        path: "approve",
        name: "ApproveOneOnOne",
        component: () => import("@components/ApproveOneOnOne.vue")
      },
      {
        path: "list",
        name: "ListOneOnOne",
        component: () => import("@components/ListOneOnOne.vue")
      },
      {
        path: "request",
        name: "CreateOneOnOne",
        component: () => import("@components/OneOnOneForm.vue")
      },
      {
        path: ":id/edit",
        name: "EditOneOnOne",
        component: () => import("@components/OneOnOneForm.vue")
      },
      {
        path: ":id/view",
        name: "ViewOneOnOne",
        component: () => import("@components/ViewOneOnOne.vue")
      },
      {
        path: ":id/join",
        name: "JoinOneOnOne",
        component: () => import("@components/JoinOneOnOne.vue")
      }
    ]
  },
  // Events
  {
    path: "/events",
    name: "Events",
    component: () => import("@routes/Events.vue"),

    children: [
      {
        path: "list",
        name: "ListEvents",
        component: () => import("@components/EventsList.vue"),
        meta: { public: true }
      },
      {
        path: "create",
        name: "CreateEvent",
        component: () => import("@components/EventForm.vue")
      },
      {
        path: ":eventId/view",
        name: "ViewEvent",
        component: () => import("@components/ViewEvent.vue"),
        meta: { public: true }
      },
      {
        path: ":eventId/review",
        name: "ListEventAttendees",
        component: () => import("@components/Attendees.vue")
      },
      {
        path: ":eventId/complete",
        name: "EventComplete",
        component: () => import("@components/EventComplete.vue")
      },
      {
        path: ":eventId/join",
        name: "JoinEvent",
        component: () => import("@routes/JoinEvent.vue")
      },
      {
        path: ":eventId",
        name: "EditEvent",
        component: () => import("@components/EventForm.vue")
      }
    ]
  },
  // Classes
  {
    path: "/classes",
    name: "Classes",
    component: () => import("@routes/Classes.vue"),

    children: [
      {
        path: "list",
        name: "ListClasses",
        component: () => import("@components/ClassList.vue"),
        meta: { public: true }
      },
      {
        path: "create",
        name: "CreateClass",
        component: () => import("@components/ClassForm.vue")
      },
      {
        path: ":classId",
        name: "EditClass",
        component: () => import("@components/ClassForm.vue")
      },
      {
        path: ":classId/complete",
        name: "ClassComplete",
        component: () => import("@components/EventComplete.vue")
      },
      {
        path: ":classId/view",
        name: "ViewClass",
        component: () => import("@components/ViewClass.vue"),
        meta: { public: true }
      },
      {
        path: ":classId/review",
        name: "ListClassAttendees",
        component: () => import("@components/Attendees.vue")
      }
    ]
  },
  // Library
  {
    path: "/library",
    name: "Library",
    component: () => import("@routes/Library.vue")
  },
  // Settings [Classes, Events, User]
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@routes/Settings.vue"),

    children: [
      {
        path: "me",
        name: "MySettings",
        component: () => import("@components/UserSettings.vue")
      },
      {
        path: "classes",
        name: "ClassSettings",
        component: () => import("@components/ClassSettings.vue")
      },
      {
        path: "events",
        name: "EventSettings",
        component: () => import("@components/EventSettings.vue")
      },
      {
        path: "zoom",
        name: "ZoomUserSettings",
        component: () => import("@components/ZoomUserSettings.vue")
      }
    ]
  },
  // 404 / Not Found
  {
    path: "*",
    name: "NotFound",
    component: () => import("@routes/NotFound.vue"),
    meta: { public: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

/**
 * Route/Navigation guards: ensures that user has permissions to view a route.
 */
router.beforeEach((to, from, next) => {
  const publicRoute = to.meta.public || false;

  // possibly refresh activity timer in case user is logged in
  session.refreshActivityTimer();

  // Move along if destination is a public route
  if (publicRoute) return next();

  // Check if a user is authenticated
  const cookieUser = Cookies.get("mvp_user") || "{}";
  const mvpUser = decodeURIComponent(cookieUser);
  const user = JSON.parse(mvpUser);

  // Check if active user has permissions
  if (user instanceof Object) {
    return !hasPagePermissions(to.name, user.role)
      ? next({ name: "NotFound", replace: true })
      : next();
  } else return next({ name: "Login", query: { next: to.path } });
});

/**
 * Route Navigation: scroll app to top after route change
 */
router.afterEach(() => {
  const $elem = document.querySelector("html");
  if ($elem) $elem.scrollTo({ top: 0 });
});

export default router;
