/* eslint-disable no-undef */

import { METHODS } from "../api.config";

/** Endpoints for `Stripe` -related events */
export default {
  checkTeacherAvailability: {
    group: "oneOnOne",
    url: ({ id }) => `${API_URL}/one-on-events/check/${id}`,
    method: METHODS.POST
  },
  deleteOneOnOne: {
    group: "oneOnOne",
    url: ({ eventId }) => `${API_URL}/one-on-events/${eventId}`,
    method: METHODS.DELETE
  },
  getOneOnOneById: {
    group: "oneOnOne",
    url: ({ eventId }) => `${API_URL}/one-on-events/${eventId}`
  },
  listOneOnOne: {
    group: "oneOnOne",
    url: ({ requestedBy = "", assignedTeacher = "" }) =>
      `${API_URL}/one-on-events?requestedBy=${requestedBy}&assignedTeacher=${assignedTeacher}`
  },
  scheduleOneOnOne: {
    group: "oneOnOne",
    url: () => `${API_URL}/one-on-events/`,
    method: METHODS.POST
  },
  updateOneOnOne: {
    group: "oneOnOne",
    url: ({ eventId }) => `${API_URL}/one-on-events/${eventId}`,
    method: METHODS.PATCH
  }
};
