import {
  extractFormFields,
  invalidFormMessage
} from "../../helpers/form.helpers";

/**
 * Components that have a standard HTML Form
 */
const FormsMixin = {
  props: { formData: Object },

  data: () => ({
    form: {},
    error: null
  }),

  watch: {
    formData: function _onFormData(newData) {
      this.initializeForm();

      if (newData) {
        this.appendFormData(extractFormFields(newData, this.requiredFields));
      }
    }
  },

  computed: {
    formIsValid() {
      return this.invalidFields.length === 0;
    },

    invalidFields() {
      return this.requiredFields.filter(field => !this.form[field]);
    }
  },

  async created() {
    // Validate props and methods
    if (!Array.isArray(this.requiredFields)) {
      const message =
        "FormMixin needs a 'requiredFields' property. " +
        "Make sure your component has a (computed or data) array property " +
        "called 'requiredFields'. It should be a list of every required field, " +
        "or an empty array if no form fields are required.";
      throw new Error(message);
    }

    this.initializeForm();
  },

  methods: {
    appendFormData(data) {
      this.form = { ...this.form, ...data };
    },

    appendAndEmit(data) {
      this.appendFormData(data);
      this.emitFormData();
    },

    clearErrors() {
      this.error = null;
    },

    emitFormData() {
      this.$emit("change", this.form);
    },

    hydrate(data) {
      this.appendFormData(data);
    },

    initializeForm() {
      this.form = extractFormFields(this.formData || {}, this.requiredFields);
    },

    onFormError(error) {
      this.error = error.message || error;
      this.loading = false;
    },

    requiredField(field) {
      const base = `h6 required`;
      return this.invalidFields.includes(field) ? `${base} error--text` : base;
    },

    setImageFile(eventTarget) {
      const imageFile = eventTarget.files[0];
      const pathSegments = eventTarget.value.split(".");
      const fileExtension = pathSegments[pathSegments.length - 1];
      this.appendFormData({ imageFile, fileExtension });
      this.emitFormData();
    },

    validateForm() {
      this.clearErrors();

      if (!this.formIsValid) {
        return (this.error = invalidFormMessage(
          this.form,
          this.requiredFields
        ));
      }
    }
  }
};

export default FormsMixin;
