<template>
  <loader v-if="loading" />

  <p v-else-if="noRegistrations" class="text--centered">
    <em>Looks like I'm free to attend some events or classes!</em>
  </p>

  <section v-else id="scheduled-registrations">
    <!-- Class Registrations -->
    <registrations-list class="column" list-type="Online Class" />

    <hr class="divider divider--vertical" />

    <!-- Event Registrations -->
    <registrations-list class="column" list-type="Event" />
  </section>
</template>

<script>
import Loader from "./Loader.vue";
import RegistrationsMixin from "./mixins/registrations.mixin";
import RegistrationsList from "./RegistrationsList.vue";

export default {
  components: { Loader, RegistrationsList },

  name: "ScheduledRegistrations",

  mixins: [RegistrationsMixin]
};
</script>

<style lang="scss">
#scheduled-registrations {
  @include flex();
  align-items: flex-start;

  > .column {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  #scheduled-registrations {
    @include flex-column();

    > .column,
    .column .title {
      width: $parent;
    }
  }
}
</style>
