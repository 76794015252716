<template>
  <component
    :is="headerItem || !event ? 'div' : 'router-link'"
    class="list-item list-item--registration"
    :class="{ 'list-item--header': !event }"
    :to="event && routeLink"
  >
    <span class="h6 ellipsis grow">
      <i class="far fa-calendar success--text" v-if="isRepeatingEvent" />
      <i class="fas fa-presentation link" v-else-if="isOnlineClass" />
      <i class="fas fa-megaphone link" v-else />&nbsp;
      <i
        v-if="showAssignmentType"
        :class="{
          'fa-bookmark error--text': true,
          fas: event.assignedTeacher.id === (activeUser || {}).id,
          far: event.assignedTeacher.id !== (activeUser || {}).id
        }"
      />
      {{ eventName }}
    </span>

    <span class="date no-shrink col-25">{{ dateTime }}</span>
    <em class="timezone no-shrink">{{ localTimeZone }}</em>
  </component>
</template>

<script>
import EventsMixin from "./mixins/events.mixin";

export default {
  name: "ScheduleListItem",

  mixins: [EventsMixin],

  props: {
    assignmentListItem: Boolean,
    headerItem: Boolean,
    schedule: Object
  },

  computed: {
    dateTime() {
      return this.schedule ? this.formattedEventDateTime : "Date/Time";
    },

    event() {
      if (!this.schedule) return null;
      const { assignmentListItem, schedule } = this;
      return assignmentListItem ? schedule : schedule.event;
    },

    eventTimeZoneLocal() {
      return this.formatTZ(this.eventDateLocal.zoneName);
    },

    eventName() {
      return this.schedule ? this.event.name : "Name";
    },

    isRepeatingEvent() {
      return this.schedule ? this.event.isRepeatingEvent : false;
    },

    localTimeZone() {
      return this.schedule ? this.eventTimeZoneLocal : "TZ";
    },

    routeLink() {
      const { classId, eventId, id } = this.event;
      return this.isOnlineClass
        ? { name: "ViewClass", params: { classId: classId || id } }
        : { name: "ViewEvent", params: { eventId: eventId || id } };
    },

    showAssignmentType() {
      const { assignmentListItem, isStudentUser } = this;
      return assignmentListItem && !isStudentUser;
    }
  }
};
</script>

<style lang="scss">
.list-item.list-item--registration {
  background-color: white;
  transition: box-shadow linear 150ms;

  /*  &,
  &:hover,
  &:active {
    text-decoration: none;
  } */

  &:hover {
    @include elevated(1px);
    background-color: transparentize($color: white, $amount: 0.15);
  }

  > a {
    line-height: $md;
    text-decoration: none;
  }

  .column.col-25 {
    align-content: flex-end;
    justify-content: flex-end;
  }

  .date {
    max-width: $quarter;
  }

  .date,
  .timezone {
    @include ellipsis();
    text-align: right;
  }

  .timezone {
    color: $grey;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: $xs;
    padding-right: $xxs;
    width: $xlg;
  }
}
</style>
