<template>
  <loader v-if="loading" />

  <section v-else id="dashboard-home" class="route">
    <h1 class="h4 title">
      {{ appOrgName }}
      <i class="fas fa-arrow-right grey-light--text"></i>
      <span class="accent--text">
        {{ activeUser ? "Dashboard" : "Home" }}
      </span>
    </h1>

    <tile-list id="home" class="tiles--centered" :tiles="links" />

    <branded-divider class="route-content" />

    <h2 v-if="activeUser" class="h4 title title--centered route-content">
      My <span class="accent--text">Schedule</span>
    </h2>
    <scheduled-registrations v-if="activeUser" class="route-content" />
  </section>
</template>

<script>
/* eslint-disable no-undef */
import Loader from "@components/Loader.vue";
import ScheduledRegistrations from "@/components/ScheduledRegistrations.vue";
import TileList from "@components/TileList.vue";
import BrandedDivider from "@/components/BrandedDivider.vue";
import PermissionsMixin from "../components/mixins/permissions.mixin";

export default {
  name: "Home",

  components: { BrandedDivider, TileList, Loader, ScheduledRegistrations },

  mixins: [PermissionsMixin],

  data: () => ({ loading: true }),

  computed: {
    links() {
      const useDefaults = !this.activeUser || this.isStudentUser;
      const eventDescription = useDefaults
        ? `We believe in fostering personal connection and community throughout our studio both online and in-person. We provide online interactive Live Stream Workshops, Free Community Events and Offerings`
        : `Schedule or manage interactive events`;

      const classesDescription = useDefaults
        ? `Our online studio offers LIVE streaming classes daily for a drop in rate of $5 per class or $24.99 for an unlimited month of yoga. Interact with teachers, ask questions and even get feedback on alignment`
        : "Schedule or manage Live Stream classes";

      const oneOnOneDesc = this.isScheduleAdminUser
        ? "View or manage existing requests"
        : "View or schedule a session with one of our Practitioners";

      const oneOnOneTo = this.isScheduleAdminUser
        ? "ApproveOneOnOne"
        : "ListOneOnOne";

      return [
        {
          name: "Online Classes",
          to: { name: "ListClasses" },
          description: classesDescription,
          actionText: `Go to Classes`,
          condition: true
        },
        {
          name: "Upcoming Events",
          to: { name: "ListEvents" },
          description: eventDescription,
          actionText: `Go to Events`,
          condition: true
        },
        {
          name: "Library",
          to: { name: "Library" },
          description: "Watch videos from past events and classes",
          actionText: `Video Library`,
          condition: Boolean(this.activeUser)
        },
        {
          name: "One-on-One",
          to: { name: oneOnOneTo },
          description: oneOnOneDesc,
          condition: Boolean(this.activeUser)
        },
        {
          name: "Users",
          to: { name: "ListUsers" },
          description: "Create or manage existing users",
          condition: Boolean(this.isAdminUser)
        }
      ].filter(({ condition }) => condition);
    }
  },

  async mounted() {
    this.attachToState();
    this.loading = false;
  }
};
</script>
