import { DateTime } from "luxon";
import {
  isAdmin,
  isAssigned,
  isStudent,
  isTeacher
} from "../models/permissions";
import state from "../state";
import { assertDate, weekDateRange } from "./date-time.helpers";

export const eventCategories = [
  "Live Stream",
  "Free Community Outdoor Yoga",
  "Community Offering",
  "In Studio",
  "In Studio/Live Stream",
  "Live Stream Community Event"
];

export const ONLINE_CLASS = "Online Class";

export const EVENT = "Event";

export const statuses = ["Open", "Closed", "Canceled", "Completed"];

export const eventTypes = [ONLINE_CLASS, EVENT];

export function eventFilters(category) {
  return [
    `Repeating ${category}`,
    `Assigned ${category}`,
    `Registered ${category}`
  ];
}

export function eventFormReset(onlineClass = true) {
  return {
    assignedTeacher: null,
    backupTeacher: null,
    eventCategory: onlineClass ? ONLINE_CLASS : eventCategories[0],
    eventStatus: statuses[0],
    eventImage: null,
    imageFile: null,
    meetingLength: 60,
    scheduledDate: DateTime.local()
      .plus({ days: 7 })
      .set({
        hour: 7,
        minute: 0,
        millisecond: 0
      })
      .toFormat("yyyy-MM-dd'T'T"),

    scheduledTimeZone: DateTime.local().zoneName,
    zoomLink: null
  };
}

/**
 * Filters a list of `Events` or `Classes` for a particular user, given
 * a `Set` of filters, a list of `Events`, and a `User`
 * @param {object} user User Id of logged in user
 * @param {Array} eventList List of Events or Classes
 * @param {Set} filters List of [class/event] list filters
 */
export function filterEventsList(user, eventList, filters) {
  const active = e => _isActiveEvent(e, user);
  if (filters.size === 0) return [...eventList].filter(active);

  // Data sources
  const { userRegistrations } = state.getState();
  const { onlineClasses, events } = userRegistrations || {};
  const allRegistrations = [];
  if (userRegistrations) {
    allRegistrations.push(...(onlineClasses || []), ...(events || []));
  }

  // Filter helpers for event types
  const assigned = e => isAssigned(user, e);
  const repeating = e => e.isRepeatingEvent || false;
  const registered = e =>
    Boolean(userRegistrations) && _isAttendee(e, allRegistrations);
  const filterFns = { repeating, assigned, registered };
  const filtersList = [...filters];

  return eventList.filter(event =>
    filtersList.reduce((agg, filter) => {
      const key = filter.toLowerCase();
      return agg && active(event) && filterFns[key](event);
    }, true)
  );
}

/* additional event filter helpers */
/**
 * Assert that event has not happened yet
 * @param {object} event Event
 * @param {boolean} event.hasEnded Computed; assert that event date is past.
 * @param {object} user Active user
 * @returns {boolean}
 */
function _isActiveEvent(event, user) {
  const { hasEnded, eventStatus } = event;
  if (!user || isStudent(user)) return !hasEnded && eventStatus === "Open";

  if (isTeacher(user)) return isAssigned(user, event) || !hasEnded;

  return isAdmin(user);
}

/**
 * Assert that the logged-in user is registered for an event
 * @param {object} event Event
 * @param {number} event.id event id
 * @param {object[]} userRegistrations List of registrations
 * @param {object[]} allRegistrations List of registrations
 * @returns {boolean}
 */
function _isAttendee({ id }, allRegistrations) {
  const match = ({ eventId, classId }) => classId === id || eventId === id;
  return allRegistrations.some(match);
}

/**
 * Filter a list of `Classes/Events` and return events that either
 * repeat on, or are scheduled for, the day of the date.
 * @param {Event[]} list Event/Class list
 * @param {DateTime} dateFilter Date context for filtering
 */
export function filterByDateAndDay(list, dateFilter) {
  return [...list].filter(event => {
    const { scheduledDate, scheduledTimeZone } = event;
    const { zoneName: zone } = dateFilter;
    // return true if event date matches date filter
    return assertDate(dateFilter, zone).isSame(
      scheduledDate,
      scheduledTimeZone
    );
  });
}

/**
 * Filter a `list` of `Classes/Events` and return those within the week
 * of `dateFilter`
 * @param {Event[]} list Event/Class list
 * @param {DateTime} dateFilter Date context for filtering
 */
export function filterForWeek(list, dateFilter) {
  return [...list].filter(event => {
    // return true if event date is within date filter start, end
    const { scheduledDate, scheduledTimeZone } = event;
    const { start, end } = weekDateRange(dateFilter);
    const scheduled = assertDate(scheduledDate, scheduledTimeZone);

    return (
      scheduled.isSameOrAfter(start, start.zoneName) &&
      scheduled.isSameOrBefore(end, end.zoneName)
    );
  });
}
