<template>
  <section class="list list--schedule">
    <!-- Class Registrations -->
    <h4 class="h5 title">
      {{ type }}
      <span class="accent--text">{{
        assignmentList ? "Assignments" : "Registrations"
      }}</span>
    </h4>

    <!-- List Legend -->
    <events-list-legend
      v-if="schedule.length"
      class="wide"
      :items="listLegend"
    />

    <!-- Header -->
    <schedule-list-item header-item />

    <!-- Empty message -->
    <p class="list-item list-item--label grey--text" v-if="!schedule.length">
      <em class="wide">
        Looks like I'm free to {{ assignmentList ? "teach" : "attend" }} some
        {{ (type === "Event" ? `${type}s` : `${type}es`).toLowerCase() }}!
      </em>
    </p>

    <!-- List Items -->
    <schedule-list-item
      v-for="(registration, i) in schedule"
      :assignment-list-item="assignmentList"
      :key="registrationElemKey(registration, i)"
      :schedule="registration"
    />
  </section>
</template>

<script>
import DateTimeMixin from "./mixins/date-time.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";
import EventsListLegend from "./EventsList.Legend.vue";
import ScheduleListItem from "./ScheduleListItem.vue";

export default {
  components: { EventsListLegend, ScheduleListItem },
  name: "ScheduleList",

  mixins: [DateTimeMixin, PermissionsMixin],

  props: {
    assignmentList: Boolean,
    schedule: { type: Array, required: true },
    onlineClass: Boolean
  },

  computed: {
    listLegend() {
      if (!this.activeUser) return [];

      const state = this.schedule.reduce(
        (agg, scheduleItem) => {
          const e = scheduleItem.event || scheduleItem;
          if (e) {
            agg.Weekly = e.isRepeatingEvent;
            agg.Substitute = e.backupTeacher.id === this.activeUser.id;
            agg["Primary Teacher"] =
              e.assignedTeacher.id === this.activeUser.id;
          }
          return agg;
        },
        {
          "Primary Teacher": false,
          Substitute: false,
          Weekly: false
        }
      );

      const legend = Object.keys(state).filter(k => state[k]);
      return legend;
    },

    type() {
      return this.onlineClass ? "Class" : "Event";
    }
  },

  methods: {
    registrationElemKey(val, index) {
      const { classId, eventId, id } = val.event || val;
      return this.onlineClass
        ? `class-${index}-${classId || id}`
        : `event-${index}-${eventId || id}`;
    }
  }
};
</script>

<style lang="scss">
.list.list--schedule {
  box-shadow: none;
  padding-bottom: $md;
  width: $parent;

  .title {
    height: $xxlg;
    line-height: $xxlg;
  }
}
</style>
