import { Cookies } from "../network";

export COUNTRIES from "./countries";
export TIMEZONES from "./timezones";
export ZOOM_TIMEZONES from "./timezones.zoom";
export Pronouns from "./pronouns";

/**
 * Convert a string from `camelCase` to `Sentence Case`
 * @param {string} str String
 * @returns {string}
 */
export function camelToSentence(str) {
  const upd = str.replace(/([A-Z])/g, " $1").trim();
  const converted = upd.charAt(0).toLocaleUpperCase() + upd.slice(1);
  return converted;
}

export function getCached(key) {
  return localStorage.getItem(key) || Cookies.get(key);
}

export function elemInView(boundingRect) {
  const { top, left, bottom, right } = boundingRect;
  const { documentElement } = window.document;
  return (
    top >= 0 &&
    left >= 0 &&
    bottom <= (window.innerHeight || documentElement.clientHeight) &&
    right <= (window.innerWidth || documentElement.clientWidth)
  );
}
