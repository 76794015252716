<template>
  <loader v-if="loading" :loading-message="loadingMessage" />

  <section v-else id="app">
    <!-- App (Branded) Header -->
    <app-header />

    <!-- Top action menu -->
    <data-action-menu v-if="showSubmenu" :routes="subroutes" />

    <!-- Consent agreements (new users) -->
    <legal-agreements v-if="requireConsent" />

    <!-- Route content (via: routes/index.js) -->
    <router-view v-else />

    <!-- Bottom action menu -->
    <data-action-menu v-if="showSubmenu" :routes="subroutes" />

    <!-- App Footer -->
    <app-footer />
  </section>
</template>

<script>
import session from "./session";
import AppFooter from "./components/AppFooter.vue";
import AppHeader from "./components/AppHeader.vue";
import AppUpdateMixin from "./components/mixins/app-update.mixin";
import DataActionMenu from "./components/DataActionMenu.vue";
import LegalAgreements from "./routes/LegalAgreements.vue";
import Loader from "./components/Loader.vue";
import PermissionsMixin from "./components/mixins/permissions.mixin";
import state from "./state";

export default {
  name: "App",

  components: {
    AppFooter,
    AppHeader,
    DataActionMenu,
    LegalAgreements,
    Loader
  },

  mixins: [AppUpdateMixin, PermissionsMixin],

  data: () => ({
    sessionStarted: false,
    subroutes: [],
    unsubscribe: null
  }),

  computed: {
    showSubmenu() {
      const { requireConsent } = this;
      return !requireConsent && this.subroutes.length > 0;
    },

    type() {
      const { path } = this.$route;
      if (path.includes("/classes/")) return "Class";
      return "Event";
    }
  },

  async mounted() {
    if (session.started) return this.stopLoading();
    const action = session.isLoggedIn ? "Re-starting" : "Starting";
    this.startLoading(`${action} App...`);

    try {
      await session.start();
    } catch (error) {
      console.warn("Application start error", error);
    } finally {
      this.onAppSession();
    }
  },

  methods: {
    onAppSession() {
      this.onAppState(state.getState());
      this.stopLoading();
      this.attachToState();
    },

    // Override: React to global app state update
    onAppState({ subroutes = [], sessionStarted, user }) {
      const logout = !user && this.activeUser;
      this.activeUser = user;
      this.sessionStarted = sessionStarted;
      this.refreshSubroutes(subroutes);

      if (logout) this.redirectToLogin();
    },

    // Redirect to login if the user isn't authenticated
    redirectToLogin() {
      const { path, name } = this.$route;
      const loginRoute = { name: "Login" };
      const skipNext =
        !name ||
        name === "MySettings" ||
        path.includes("/login") ||
        path === "/";

      this.$router.push(
        skipNext ? loginRoute : { ...loginRoute, query: { next: path } }
      );
      this.loading = false;
    },

    refreshSubroutes(subroutes) {
      const { accessibleRoutes } = this;
      const accessibleRoute = r =>
        Object.keys(r).includes("condition")
          ? accessibleRoutes.includes(r.name) && r.condition
          : accessibleRoutes.includes(r.name);

      this.subroutes = subroutes.filter(accessibleRoute);
    }
  }
};
</script>

<style src="./App.scss" lang="scss"></style>
