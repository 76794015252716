import {
  assertNow,
  currentTimeUTC,
  utcFromISO
} from "../helpers/date-time.helpers";
import { ONLINE_CLASS } from "../helpers/classes-events.helpers";
import { findTZByText } from "./zoom";

const toBoolean = value => (typeof value === "boolean" ? value : false);
const EVENT_BASE = data => ({
  name: data.name || null,
  assignedTeacher: data.assignedTeacher || null,
  backupTeacher: data.backupTeacher || null,
  eventCategory: data.eventCategory || null,
  eventStatus: data.eventStatus || null,
  eventCap: data.eventCap || null,
  eventImage: data.eventImage || null,
  eventLink: data.eventLink || null,
  isRepeatingEvent: toBoolean(data.isRepeatingEvent),
  description: data.description || null,
  productId: data.productId || null,
  registrationStatus: data.registrationStatus || null,
  scheduledDate: data.scheduledDate || null,
  scheduledEndDate: data.scheduledEndDate || null,
  scheduledTimeZone: data.scheduledTimeZone || null,
  zoomLink: data.zoomLink || null,
  zoomStartLink: data.zoomStartLink || null,
  zoomMeetingId: data.zoomMeetingId || null,
  occId: data.occId || null,
  // INTERNAL
  absentees: data.absentees || []
});

export default function MVPEvent(data = {}) {
  const { scheduledDate = null, scheduledTimeZone = null } = data;
  const isOnlineClass = data.eventCategory === ONLINE_CLASS;
  const eventDateTimeUTC = utcFromISO(scheduledDate, scheduledTimeZone);
  const eventEndDateTimeUTC = eventDateTimeUTC.plus({ hours: 2 });

  const event = {
    ...EVENT_BASE(data),

    // getters
    get eventDateTime() {
      return eventDateTimeUTC.setZone(scheduledTimeZone);
    },

    get eventDateTimeToRelative() {
      if (!eventDateTimeUTC.isValid) return "Invalid date";

      const nowUTC = currentTimeUTC();
      const minsMS = 1000 * 60;
      const hoursMS = minsMS * 60;
      const daysMS = hoursMS * 24;
      const diff = eventDateTimeUTC.diff(nowUTC).toMillis();
      let unit;

      switch (true) {
        case Math.floor(Math.abs(diff / daysMS)) > 0:
          unit = "days";
          break;
        case Math.floor(Math.abs(diff / hoursMS)) > 0:
          unit = "hours";
          break;
        case Math.floor(Math.abs(diff / minsMS)) > 0:
          unit = "minutes";
          break;
        default:
          return eventDateTimeUTC.toRelative();
      }

      return eventDateTimeUTC.toRelative({ unit });
    },

    get eventDateTimeUTC() {
      return eventDateTimeUTC;
    },

    get eventEndDateTime() {
      return eventEndDateTimeUTC.setZone(scheduledTimeZone);
    },

    get eventEndDateTimeUTC() {
      return eventEndDateTimeUTC;
    },

    get eventTZAbbr() {
      return findTZByText(scheduledTimeZone).abbr;
    },

    get typePlural() {
      return isOnlineClass ? "Classes" : "Events";
    },

    get type() {
      return isOnlineClass ? "Class" : "Event";
    },

    get isComplete() {
      return this.eventStatus === "Completed";
    },

    get hasEnded() {
      const end = eventEndDateTimeUTC;
      const endArgs = [end, end.zoneName];
      if (this.happensToday) {
        return (
          assertNow().isSameOrAfter(...endArgs) &&
          assertNow().isSameOrAfter(...endArgs, "hour")
        );
      }

      return assertNow().isSameOrAfter(...endArgs);
    },

    get happensToday() {
      const today = currentTimeUTC();
      return today.hasSame(eventDateTimeUTC, "day");
    },

    get hasStarted() {
      const now = assertNow();
      const end = eventEndDateTimeUTC;
      const start = eventDateTimeUTC;

      return (
        this.happensToday &&
        now.isSameOrAfter(start, start.zoneName, "minute") &&
        now.isSameOrBefore(end, end.zoneName, "minute")
      );
    },

    get canBeJoined() {
      const now = assertNow();
      const end = eventEndDateTimeUTC;

      const start = eventDateTimeUTC.minus({ minutes: 10 });

      return (
        this.happensToday &&
        now.isSameOrAfter(start, start.zoneName, "minute") &&
        now.isSameOrBefore(end, end.zoneName, "minute")
      );
    },

    get isEvent() {
      return !this.isOnlineClass;
    },

    get isInProgress() {
      return this.hasStarted && !this.hasEnded;
    },

    get isOnlineClass() {
      return isOnlineClass;
    },

    get registrationOpen() {
      const isOpen = val => val === "Open";
      const willStart = assertNow().isSameOrBefore(eventDateTimeUTC);
      const openStatus = isOpen(this.eventStatus);
      const openRegistration = isOpen(this.registrationStatus);

      return willStart && openStatus && openRegistration;
    }
  };

  if (data.id) event.id = data.id;

  return event;
}

export function EventProduct(data) {
  return {
    name: data.name || null,
    active: data.active || true,
    description: data.description || null,
    currency: "USD",
    amount: data.amount || null
  };
}
