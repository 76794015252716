import apiConfig from "../network";

/**
 * Represents of a My Vinyasa Practiice `Product` (from `Stripe`)
 * @param {object} data Data for translation
 */
function MVPProduct(data = {}) {
  return {
    id: data.id || null,
    name: data.name || null,
    description: data.description || null,
    images: data.images || null,
    price: (data.price || []).map(MVPPrice),
    metadata: data.metadata || {}
  };
}

function MVPPrice(d) {
  return {
    id: d.id || null,
    currency: d.currency,
    unit_amount: d.unit_amount || null,
    unit_amount_decimal: d.unit_amoun_decimal || null,
    product: d.product || null,
    type: d.type || null,
    nickname: d.nickname,

    get displayPrice() {
      return d.unit_amount / 100;
    }
  };
}

export const stripeElementOpts = { fonts: [] };

/**
 *
 * @param {object} params Request params
 * @param {string} params.firstName User first name
 * @param {string} params.lastName User last name
 * @param {string} params.email User email
 * @param {string} params.phoneNumber (optional) User phone number
 */
export async function createCustomer(params) {
  const { customer } = await apiConfig.billing.createCustomer(params);
  return customer;
}

/**
 * Create a `PaymentIntent` (Stripe API) which tracks a user's payment
 * lifecyle and ensures they only get charged once.
 * @param {object} params Req params
 * @param {string} params.customerId User `stripeAcctId`
 * @param {string} params.userEmail User `email`
 * @param {string} params.productId Target `Stripe Product Id` that user intends to buy
 */
export async function createPaymentIntent(params) {
  const { secret } = await apiConfig.billing.createPaymentIntent(params);
  return secret;
}

/**
 * Create a Donation `PaymentIntent` (Stripe API) which tracks a user's payment
 * lifecyle and ensures they only get charged once.
 * @param {object} params Req params
 * @param {string} params.name User First + Last name
 * @param {string} params.email User `email`
 * @param {string} params.amount Donation amount
 */
export async function createDonationIntent(params) {
  const { secret } = await apiConfig.billing.createDonationIntent(params);
  return secret;
}

export async function createStripeProduct(params) {
  const { productRet } = await apiConfig.billing.createStripeProduct(params);
  return MVPProduct(productRet);
}

/**
 * Create an MVP subscription via the `Stripe` API
 * @param {object} params Request params
 * @param {object} params.customerId User's `Stripe` userID
 * @param {object} params.priceId Subscription SKU in `Stripe`
 * @param {object} params.paymentMethodId Payment Method id (e.g. card, direct transfer)
 */
export async function createSubscription(params) {
  const { subscription, error } = await apiConfig.billing.createSubscription(
    params
  );
  if (error) throw new Error(error.message);
  return subscription;
}

/**
 * Cancel an MVP subscription via the `Stripe` API
 * @param {object} params Request params
 * @param {object} params.subId Subscription ID
 */
export async function cancelSubscription(params) {
  const { deleted } = await apiConfig.billing.cancelSubscription(params);
  return deleted;
}

export async function getProductById(productId) {
  const { product } = await apiConfig.billing.getProductById({ productId });
  return MVPProduct(product);
}

export async function manageSubscription(customer) {
  const { url } = await apiConfig.billing.createPortalSession({ customer });
  return (window.location.href = url);
}

/**
 * List all MVP `Stripe` products that can be purchased/subscribed to.
 */
export async function listProducts() {
  const { products } = await apiConfig.billing.listProducts();
  return products.map(MVPProduct);
}

/**
 * List all MVP `Stripe` Membership products
 */
export async function listMembershipProducts() {
  const { products } = await apiConfig.billing.listMembershipProducts();
  return products.map(MVPProduct);
}
