import APIConfig from "./api.config";
import endpoints from "./endpoints";
import cookies from "./cookies";
import Cookies from "./cookies";
import { getCached } from "../utilities";

export { cookies as Cookies };

/**
 * `APIConfig` Instance
 */
export const apiConfig = new APIConfig(endpoints, onGlobalNetworkError);

// Limit number of request retries
let retryCount = 0;

/**
 * Global HTTP/S request error handler
 * @param {Promise} error Rejected promise from `APIConfig`
 * @param {number} responseCode Server request response code
 * @param {{ url: string, reqConfig: Object }} requestParams Params for reconstructing request
 */
function onGlobalNetworkError(error, responseCode, requestParams) {
  return (
    error
      .catch(async e => {
        // Refresh token and attempt to re-try the request
        if (responseCode > 400 || responseCode === -1) {
          if (e.message === "jwt expired") {
            return await apiConfig.refreshToken();
          }
        }

        // Return error so caller can handle it
        return Promise.reject(e.error || e);
      })
      // If here, token refresh succeeded: re-attempt the request
      .then(onAccessTokenRefreshed)
      // if we get here, we got a successful response from the retried request
      .then(data => data.json())
  );

  /**
   * Handler: store updated refresh token and retry request
   * @param {{access_token: string, auth_until: string}} authResponse
   */
  function onAccessTokenRefreshed(authResponse) {
    // Store the refreshed token
    if (authResponse) {
      WriteUserCookieFallback(authResponse);

      // replace expired access-token
      const { headers } = requestParams.reqConfig;
      if (headers && headers.Authorization) {
        const token = getCached("access_token");
        requestParams.reqConfig.headers.Authorization = `Bearer ${token}`;
      }
    }

    // Retry the request if retry threshold not met
    if (requestParams && retryCount < 5) {
      retryCount += 1;
      const { url, reqConfig } = requestParams;
      return fetch(new Request(url, reqConfig));
    }

    // fallback msg: If we're retrying another request, just reject this one
    retryCount = 0;
    const m = "There was an error with the last request: please try again.";
    return Promise.reject(m);
  }
}

export default apiConfig;

/**
 * Uploads a file to AWS S3 via MVP backend
 * @param {object} file Form file data
 * @param {object} opts Request options
 * @param {string} opts.fileNameRef Ref that will be used to generate file name
 * @param {string} opts.fileExtension File extension (e.g. `png`)
 * @param {string} opts.fileContext File creation context (e.g. `user-profile`)
 */
export async function uploadFile(file, opts) {
  const { fileNameRef, fileExtension, fileContext } = opts;
  const fileName = fileNameRef
    .toLowerCase()
    .substring(0, 8)
    .concat(`-${fileContext}.${fileExtension}`)
    .replace(" ", "-");

  const body = new FormData();
  body.append("file", file, fileName);

  const result = await apiConfig.uploadFile({ body });
  const { fileUrl, message = null } = result;

  if (message) throw new Error(message);

  return fileUrl;
}

/**
 * Manually write cookie and auth data if app is running in mobile-native context
 * @param {object|undefined|null} authResponse Response from server
 * @returns {object} response data or empty object
 */
export function WriteUserCookieFallback(authResponse = {}) {
  if (
    !authResponse ||
    !Object.keys(authResponse).length ||
    authResponse.message
  ) {
    return authResponse;
  }

  const data = authResponse.data || authResponse;
  const keys = ["access_token", "refresh_token", "auth_until", "mvp_user"];
  const setCookie = k => {
    let val =
      k === "mvp_user" && data.user
        ? encodeURIComponent(JSON.stringify(data.user))
        : data[k] || null;
    if (val) Cookies.set(k, val);
  };

  keys.forEach(setCookie);
  return Promise.resolve(authResponse);
}

/* Suppress Network error logging to console */
window.onunhandledrejection = function onUnhandledRejection(errorEvent) {
  errorEvent.preventDefault();
  const { reason } = errorEvent;
  console.warn(reason);
};
