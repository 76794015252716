import { DateTime } from "luxon";
import { assertDate, utcFromISO } from "@/helpers/date-time.helpers";
import { findTZByText } from "@/models/zoom";
import {
  currentTimeUTC,
  dateTimeForServer
} from "../../helpers/date-time.helpers";

/**
 * Components with `DateTime` and timezone (luxon) concerns
 */
const DateTimeMixin = {
  data: () => ({
    dateInputFormat: "yyyy-LL-dd'T'HH:mm",
    dateShortFormat: "DD",
    dateTimeShortFormat: "ff",
    dateUTCFormat: "yyyy-LL-dd'T'HH:mm:ss.SSS'Z'"
  }),

  computed: {
    localTZ() {
      return DateTime.local().zoneName;
    }
  },

  methods: {
    assertDate(date1, zone) {
      return assertDate(date1, zone);
    },

    utcFromISO,

    formatDate(date) {
      const formatted = d => d.toFormat(`ff',' ZZZZ`).replace(":00", "");
      const dt = utcFromISO(date).setZone(date.zoneName);
      return formatted(dt);
    },

    formatTZ(tz) {
      const formatted = findTZByText(tz);
      return formatted.abbr;
    },

    dateTimeNow() {
      return currentTimeUTC();
    },

    dateTimeForServer
  }
};

export default DateTimeMixin;
