/* eslint-disable no-undef */
import { DateTime } from "luxon";
import { ONLINE_CLASS } from "../../helpers/classes-events.helpers";
import DateTimeMixin from "./date-time.mixin";
import PermissionsMixin from "./permissions.mixin";

/**
 * Mixins for a component that holds an `Event` or `Online Class`
 */
const EventsMixin = {
  data: () => ({ error: null, loading: true }),

  mixins: [DateTimeMixin, PermissionsMixin],

  computed: {
    assigned() {
      return this.isAssigned(this.event);
    },
    assignmentClass() {
      const { assignedTeacher } = this.event;
      const root = assignedTeacher.id === this.activeUser.id ? "fas" : "far";
      return `${root} fa-bookmark error--text`;
    },
    classId() {
      return this.$route.params.classId || null;
    },
    data() {
      return this.event || this.onlineClass || {};
    },
    entryClosed() {
      const { eventStatus } = this.event || {};
      return this.inProgress || this.hasEnded || eventStatus !== "Open";
    },
    eventDate() {
      return this.data.eventDateTime;
    },
    eventDateLocal() {
      const zone = this.activeUser
        ? this.activeUser.timeZone
        : DateTime.local().zoneName;

      return this.eventDate.setZone(zone);
    },
    eventId() {
      if (this.event && this.event.id) return this.event.id;
      const { eventId } = this.$route.params;
      return eventId ? Number(eventId) : null;
    },
    eventParams() {
      const { activeUser } = this;
      const params = { [this.idKey]: this.eventId };

      if (activeUser) {
        params.userId = activeUser.id;
        if (this.isTeacherUser || this.isAdminUser) {
          params.teacherId = activeUser.id;
        }
      }

      return params;
    },
    eventTime() {
      return this.eventDate.toFormat("t ZZZZ").replace(":00", "");
    },
    eventTimeLocal() {
      return this.eventDateLocal.toFormat("t ZZZZ").replace(":00", "");
    },
    formattedEventDate() {
      const dateDT = this.eventDateLocal;

      return dateDT.toLocaleString({
        weekday: "short",
        month: "short",
        day: "2-digit"
      });
    },
    formattedEventDateTime() {
      const dateDT = this.eventDateLocal;
      const hourMinuteLocale = { hour: "numeric", minute: "2-digit" };
      const time = dateDT.toLocaleString(hourMinuteLocale).replace(":00", "");
      return `${this.formattedEventDate}, ${time}`;
    },
    hasEnded() {
      return (this.event || {}).hasEnded;
    },
    idKey() {
      return `${this.type.toLowerCase()}Id`;
    },
    inProgress() {
      return this.event ? this.event.isInProgress : false;
    },
    isListPath() {
      return new RegExp("^List").test(this.$route.name);
    },
    isOnlineClass() {
      return this.event
        ? this.event.eventCategory === ONLINE_CLASS
        : this.$route.path.includes("/classes");
    },
    isCanceled() {
      return (this.event || {}).eventStatus === "Canceled";
    },
    listRoute() {
      return this.isOnlineClass ? "ListClasses" : "ListEvents";
    },
    productId() {
      return this.event.productId || null;
    },
    registrationClosed() {
      return !(this.event || {}).registrationOpen;
    },
    type() {
      return this.data.type || "";
    },
    viewEventRoute() {
      return {
        name: `View${this.type}`,
        params: { [this.idKey]: this.eventId }
      };
    }
  }
};

export default EventsMixin;
