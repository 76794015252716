import TIMEZONES from "./timezones";

const ZOOM_TIMEZONES = [
  {
    abbr: abbr("Pacific/Midway"),
    text: "Pacific/Midway",
    name: "Midway Island, Samoa"
  },
  {
    abbr: abbr("Pacific/Pago_Pago"),
    text: "Pacific/Pago_Pago",
    name: "Pago Pago"
  },
  { abbr: abbr("Pacific/Honolulu"), text: "Pacific/Honolulu", name: "Hawaii" },
  {
    abbr: abbr("America/Anchorage"),
    text: "America/Anchorage",
    name: "Alaska"
  },
  {
    abbr: abbr("America/Vancouver"),
    text: "America/Vancouver",
    name: "Vancouver"
  },
  {
    abbr: abbr("America/Los_Angeles"),
    text: "America/Los_Angeles",
    name: "Pacific Time (US and Canada)"
  },
  { abbr: abbr("America/Tijuana"), text: "America/Tijuana", name: "Tijuana" },
  {
    abbr: abbr("America/Edmonton"),
    text: "America/Edmonton",
    name: "Edmonton"
  },
  {
    abbr: abbr("America/Denver"),
    text: "America/Denver",
    name: "Mountain Time (US and Canada)"
  },
  { abbr: abbr("America/Phoenix"), text: "America/Phoenix", name: "Arizona" },
  {
    abbr: abbr("America/Mazatlan"),
    text: "America/Mazatlan",
    name: "Mazatlan"
  },
  {
    abbr: abbr("America/Winnipeg"),
    text: "America/Winnipeg",
    name: "Winnipeg"
  },
  {
    abbr: abbr("America/Regina"),
    text: "America/Regina",
    name: "Saskatchewan"
  },
  {
    abbr: abbr("America/Chicago"),
    text: "America/Chicago",
    name: "Central Time (US and Canada)"
  },
  {
    abbr: abbr("America/Mexico_City"),
    text: "America/Mexico_City",
    name: "Mexico City"
  },
  {
    abbr: abbr("America/Guatemala"),
    text: "America/Guatemala",
    name: "Guatemala"
  },
  {
    abbr: abbr("America/El_Salvador"),
    text: "America/El_Salvador",
    name: "El Salvador"
  },
  { abbr: abbr("America/Managua"), text: "America/Managua", name: "Managua" },
  {
    abbr: abbr("America/Costa_Rica"),
    text: "America/Costa_Rica",
    name: "Costa Rica"
  },
  {
    abbr: abbr("America/Montreal"),
    text: "America/Montreal",
    name: "Montreal"
  },
  {
    abbr: abbr("America/New_York"),
    text: "America/New_York",
    name: "Eastern Time (US and Canada)"
  },
  {
    abbr: abbr("America/Indianapolis"),
    text: "America/Indianapolis",
    name: "Indiana (East)"
  },
  { abbr: abbr("America/Panama"), text: "America/Panama", name: "Panama" },
  { abbr: abbr("America/Bogota"), text: "America/Bogota", name: "Bogota" },
  { abbr: abbr("America/Lima"), text: "America/Lima", name: "Lima" },
  { abbr: abbr("America/Halifax"), text: "America/Halifax", name: "Halifax" },
  {
    abbr: abbr("America/Puerto_Rico"),
    text: "America/Puerto_Rico",
    name: "Puerto Rico"
  },
  { abbr: abbr("America/Caracas"), text: "America/Caracas", name: "Caracas" },
  {
    abbr: abbr("America/Santiago"),
    text: "America/Santiago",
    name: "Santiago"
  },
  {
    abbr: abbr("America/St_Johns"),
    text: "America/St_Johns",
    name: "Newfoundland and Labrador"
  },
  {
    abbr: abbr("America/Montevideo"),
    text: "America/Montevideo",
    name: "Montevideo"
  },
  {
    abbr: abbr("America/Araguaina"),
    text: "America/Araguaina",
    name: "Brasilia"
  },
  {
    abbr: abbr("America/Argentina"),
    text: "America/Argentina/Buenos_Aires",
    name: "Buenos Aires, Georgetown"
  },
  {
    abbr: abbr("America/Godthab"),
    text: "America/Godthab",
    name: "Greenland"
  },
  {
    abbr: abbr("America/Sao_Paulo"),
    text: "America/Sao_Paulo",
    name: "Sao Paulo"
  },
  { abbr: abbr("Atlantic/Azores"), text: "Atlantic/Azores", name: "Azores" },
  {
    abbr: abbr("Canada/Atlantic"),
    text: "Canada/Atlantic",
    name: "Atlantic Time (Canada)"
  },
  {
    abbr: abbr("Atlantic/Cape_Verde"),
    text: "Atlantic/Cape_Verde",
    name: "Cape Verde Islands"
  },
  { abbr: abbr("UTC"), text: "UTC", name: "Universal Time UTC" },
  {
    abbr: abbr("Etc/Greenwich"),
    text: "Etc/Greenwich",
    name: "Greenwich Mean Time"
  },
  {
    abbr: abbr("Europe/Belgrade"),
    text: "Europe/Belgrade",
    name: "Belgrade, Bratislava, Ljubljana"
  },
  { abbr: abbr("CET"), text: "CET", name: "Sarajevo, Skopje, Zagreb" },
  {
    abbr: abbr("Atlantic/Reykjavik"),
    text: "Atlantic/Reykjavik",
    name: "Reykjavik"
  },
  { abbr: abbr("Europe/Dublin"), text: "Europe/Dublin", name: "Dublin" },
  { abbr: abbr("Europe/London"), text: "Europe/London", name: "London" },
  { abbr: abbr("Europe/Lisbon"), text: "Europe/Lisbon", name: "Lisbon" },
  {
    abbr: abbr("Africa/Casablanca"),
    text: "Africa/Casablanca",
    name: "Casablanca"
  },
  {
    abbr: abbr("Africa/Nouakchott"),
    text: "Africa/Nouakchott",
    name: "Nouakchott"
  },
  { abbr: abbr("Europe/Oslo"), text: "Europe/Oslo", name: "Oslo" },
  {
    abbr: abbr("Europe/Copenhagen"),
    text: "Europe/Copenhagen",
    name: "Copenhagen"
  },
  { abbr: abbr("Europe/Brussels"), text: "Europe/Brussels", name: "Brussels" },
  {
    abbr: abbr("Europe/Berlin"),
    text: "Europe/Berlin",
    name: "Amsterdam, Berlin, Rome, Stockholm, Vienna"
  },
  { abbr: abbr("Europe/Helsinki"), text: "Europe/Helsinki", name: "Helsinki" },
  {
    abbr: abbr("Europe/Amsterdam"),
    text: "Europe/Amsterdam",
    name: "Amsterdam"
  },
  { abbr: abbr("Europe/Rome"), text: "Europe/Rome", name: "Rome" },
  {
    abbr: abbr("Europe/Stockholm"),
    text: "Europe/Stockholm",
    name: "Stockholm"
  },
  { abbr: abbr("Europe/Vienna"), text: "Europe/Vienna", name: "Vienna" },
  {
    abbr: abbr("Europe/Luxembourg"),
    text: "Europe/Luxembourg",
    name: "Luxembourg"
  },
  { abbr: abbr("Europe/Paris"), text: "Europe/Paris", name: "Paris" },
  { abbr: abbr("Europe/Zurich"), text: "Europe/Zurich", name: "Zurich" },
  { abbr: abbr("Europe/Madrid"), text: "Europe/Madrid", name: "Madrid" },
  {
    abbr: abbr("Africa/Bangui"),
    text: "Africa/Bangui",
    name: "West Central Africa"
  },
  { abbr: abbr("Africa/Algiers"), text: "Africa/Algiers", name: "Algiers" },
  { abbr: abbr("Africa/Tunis"), text: "Africa/Tunis", name: "Tunis" },
  {
    abbr: abbr("Africa/Harare"),
    text: "Africa/Harare",
    name: "Harare, Pretoria"
  },
  { abbr: abbr("Africa/Nairobi"), text: "Africa/Nairobi", name: "Nairobi" },
  { abbr: abbr("Europe/Warsaw"), text: "Europe/Warsaw", name: "Warsaw" },
  {
    abbr: abbr("Europe/Prague"),
    text: "Europe/Prague",
    name: "Prague Bratislava"
  },
  { abbr: abbr("Europe/Budapest"), text: "Europe/Budapest", name: "Budapest" },
  { abbr: abbr("Europe/Sofia"), text: "Europe/Sofia", name: "Sofia" },
  { abbr: abbr("Europe/Istanbul"), text: "Europe/Istanbul", name: "Istanbul" },
  { abbr: abbr("Europe/Athens"), text: "Europe/Athens", name: "Athens" },
  {
    abbr: abbr("Europe/Bucharest"),
    text: "Europe/Bucharest",
    name: "Bucharest"
  },
  { abbr: abbr("Asia/Nicosia"), text: "Asia/Nicosia", name: "Nicosia" },
  { abbr: abbr("Asia/Beirut"), text: "Asia/Beirut", name: "Beirut" },
  { abbr: abbr("Asia/Damascus"), text: "Asia/Damascus", name: "Damascus" },
  { abbr: abbr("Asia/Jerusalem"), text: "Asia/Jerusalem", name: "Jerusalem" },
  { abbr: abbr("Asia/Amman"), text: "Asia/Amman", name: "Amman" },
  { abbr: abbr("Africa/Tripoli"), text: "Africa/Tripoli", name: "Tripoli" },
  { abbr: abbr("Africa/Cairo"), text: "Africa/Cairo", name: "Cairo" },
  {
    abbr: abbr("Africa/Johannesburg"),
    text: "Africa/Johannesburg",
    name: "Johannesburg"
  },
  { abbr: abbr("Europe/Moscow"), text: "Europe/Moscow", name: "Moscow" },
  { abbr: abbr("Asia/Baghdad"), text: "Asia/Baghdad", name: "Baghdad" },
  { abbr: abbr("Asia/Kuwait"), text: "Asia/Kuwait", name: "Kuwait" },
  { abbr: abbr("Asia/Riyadh"), text: "Asia/Riyadh", name: "Riyadh" },
  { abbr: abbr("Asia/Bahrain"), text: "Asia/Bahrain", name: "Bahrain" },
  { abbr: abbr("Asia/Qatar"), text: "Asia/Qatar", name: "Qatar" },
  { abbr: abbr("Asia/Aden"), text: "Asia/Aden", name: "Aden" },
  { abbr: abbr("Asia/Tehran"), text: "Asia/Tehran", name: "Tehran" },
  { abbr: abbr("Africa/Khartoum"), text: "Africa/Khartoum", name: "Khartoum" },
  { abbr: abbr("Africa/Djibouti"), text: "Africa/Djibouti", name: "Djibouti" },
  {
    abbr: abbr("Africa/Mogadishu"),
    text: "Africa/Mogadishu",
    name: "Mogadishu"
  },
  { abbr: abbr("Asia/Dubai"), text: "Asia/Dubai", name: "Dubai" },
  { abbr: abbr("Asia/Muscat"), text: "Asia/Muscat", name: "Muscat" },
  {
    abbr: abbr("Asia/Baku"),
    text: "Asia/Baku",
    name: "Baku, Tbilisi, Yerevan"
  },
  { abbr: abbr("Asia/Kabul"), text: "Asia/Kabul", name: "Kabul" },
  {
    abbr: abbr("Asia/Yekaterinburg"),
    text: "Asia/Yekaterinburg",
    name: "Yekaterinburg"
  },
  {
    abbr: abbr("Asia/Tashkent"),
    text: "Asia/Tashkent",
    name: "Islamabad, Karachi, Tashkent"
  },
  { abbr: abbr("Asia/Calcutta"), text: "Asia/Calcutta", name: "India" },
  { abbr: abbr("Asia/Kathmandu"), text: "Asia/Kathmandu", name: "Kathmandu" },
  {
    abbr: abbr("Asia/Novosibirsk"),
    text: "Asia/Novosibirsk",
    name: "Novosibirsk"
  },
  { abbr: abbr("Asia/Almaty"), text: "Asia/Almaty", name: "Almaty" },
  { abbr: abbr("Asia/Dacca"), text: "Asia/Dacca", name: "Dacca" },
  {
    abbr: abbr("Asia/Krasnoyarsk"),
    text: "Asia/Krasnoyarsk",
    name: "Krasnoyarsk"
  },
  { abbr: abbr("Asia/Dhaka"), text: "Asia/Dhaka", name: "Astana, Dhaka" },
  { abbr: abbr("Asia/Bangkok"), text: "Asia/Bangkok", name: "Bangkok" },
  { abbr: abbr("Asia/Saigon"), text: "Asia/Saigon", name: "Vietnam" },
  { abbr: abbr("Asia/Jakarta"), text: "Asia/Jakarta", name: "Jakarta" },
  {
    abbr: abbr("Asia/Irkutsk"),
    text: "Asia/Irkutsk",
    name: "Irkutsk, Ulaanbaatar"
  },
  {
    abbr: abbr("Asia/Shanghai"),
    text: "Asia/Shanghai",
    name: "Beijing, Shanghai"
  },
  { abbr: abbr("Asia/Hong_Kong"), text: "Asia/Hong_Kong", name: "Hong Kong" },
  { abbr: abbr("Asia/Taipei"), text: "Asia/Taipei", name: "Taipei" },
  {
    abbr: abbr("Asia/Kuala_Lumpur"),
    text: "Asia/Kuala_Lumpur",
    name: "Kuala Lumpur"
  },
  { abbr: abbr("Asia/Singapore"), text: "Asia/Singapore", name: "Singapore" },
  { abbr: abbr("Australia/Perth"), text: "Australia/Perth", name: "Perth" },
  { abbr: abbr("Asia/Yakutsk"), text: "Asia/Yakutsk", name: "Yakutsk" },
  { abbr: abbr("Asia/Seoul"), text: "Asia/Seoul", name: "Seoul" },
  {
    abbr: abbr("Asia/Tokyo"),
    text: "Asia/Tokyo",
    name: "Osaka, Sapporo, Tokyo"
  },
  { abbr: abbr("Australia/Darwin"), text: "Australia/Darwin", name: "Darwin" },
  {
    abbr: abbr("Australia/Adelaide"),
    text: "Australia/Adelaide",
    name: "Adelaide"
  },
  {
    abbr: abbr("Asia/Vladivostok"),
    text: "Asia/Vladivostok",
    name: "Vladivostok"
  },
  {
    abbr: abbr("Pacific/Port_Moresby"),
    text: "Pacific/Port_Moresby",
    name: "Guam, Port Moresby"
  },
  {
    abbr: abbr("Australia/Brisbane"),
    text: "Australia/Brisbane",
    name: "Brisbane"
  },
  {
    abbr: abbr("Australia/Sydney"),
    text: "Australia/Sydney",
    name: "Canberra, Melbourne, Sydney"
  },
  { abbr: abbr("Australia/Hobart"), text: "Australia/Hobart", name: "Hobart" },
  { abbr: abbr("Asia/Magadan"), text: "Asia/Magadan", name: "Magadan" },
  { abbr: abbr("SST"), text: "SST", name: "Solomon Islands" },
  {
    abbr: abbr("Pacific/Noumea"),
    text: "Pacific/Noumea",
    name: "New Caledonia"
  },
  { abbr: abbr("Asia/Kamchatka"), text: "Asia/Kamchatka", name: "Kamchatka" },
  {
    abbr: abbr("Pacific/Fiji"),
    text: "Pacific/Fiji",
    name: "Fiji Islands, Marshall Islands"
  },
  {
    abbr: abbr("Pacific/Auckland"),
    text: "Pacific/Auckland",
    name: "Auckland, Wellington"
  },
  {
    abbr: abbr("Asia/Kolkata"),
    text: "Asia/Kolkata",
    name: "Mumbai, Kolkata, New Delhi"
  },
  { abbr: abbr("Europe/Kiev"), text: "Europe/Kiev", name: "Kiev" },
  {
    abbr: abbr("America/Tegucigalpa"),
    text: "America/Tegucigalpa",
    name: "Tegucigalpa"
  },
  {
    abbr: abbr("Pacific/Apia"),
    text: "Pacific/Apia",
    name: "Independent State of Samoa"
  }
];

export default ZOOM_TIMEZONES;

function abbr(text) {
  const matchTz = ({ utc, abbr }) => utc.includes(text) || abbr === text;
  const m = TIMEZONES.find(matchTz);
  return m.abbr || null;
}
