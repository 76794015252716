/**
 * Shared behavior for components that, when active, need to listen for
 * an `escape` key event or general window click in order to exit their
 * active state. Additional key event listeners can be implemented as
 * needed in the `onKeyEvent` method.
 */
const KeyboardEventsMixin = {
  data: () => ({ listenersActive: false, refreshing: false }),

  computed: {
    listeners() {
      return [
        ["click", this.exitActiveState, false],
        ["keydown", this.onKeyEvent, false]
      ];
    }
  },

  beforeDestroy() {
    this.listeners.forEach(detachListener);
  },

  methods: {
    attachListeners() {
      if (this.listeners.length === 0) return;
      else if (this.listenersActive) this.detachListeners();

      this.listeners.forEach(attachListener);
      this.listenersActive = true;
    },

    detachListeners() {
      if (this.listeners.length === 0 || !this.listenersActive) return;
      this.listeners.forEach(detachListener);
      this.listenersActive = false;
    },

    /**
     * Use this to exit without applying changes when user hits 'escape'
     * while component is active. Implement override in component, and
     * ALWAYS call `detachListeners` when done.
     */
    cancelActiveState() {
      this.exitActiveState();
    },

    /**
     * Use this to exit component's "active" state and apply any changes. Override
     * in component, and ALWAYS call `detachListeners` when done.
     */
    exitActiveState() {
      this.detachListeners();
    },

    onKeyEvent({ key }) {
      if (key === "Escape") this.cancelActiveState();
    }
  }
};

export default KeyboardEventsMixin;

/* Helpers */
/**
 * Attach event listener to window
 * @param {array} args listener args
 * @param {string} name event name (e.g. `click`)
 * @param {Function} methodRef function to call when event `name` is triggered
 * @param {boolean} function function to call when event `name` is triggered
 * @returns {number}
 */
function attachListener([name, methodRef, opts]) {
  return window.addEventListener(name, methodRef, opts);
}

/**
 * Remove event listener from window
 * @param {array} args listener args
 * @param {string} name event name (e.g. `click`)
 * @param {Function} methodRef function to call when event `name` is triggered
 * @param {boolean} function function to call when event `name` is triggered
 * @returns {number}
 */
function detachListener([name, methodRef, opts]) {
  return window.removeEventListener(name, methodRef, opts);
}
