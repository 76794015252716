<template>
  <section class="registrations-list">
    <loader v-if="loading" />

    <schedule-list :online-class="isOnlineClassList" :schedule="schedule" />

    <hr class="divider" />

    <schedule-list
      v-if="!isStudentUser"
      assignment-list
      :online-class="isOnlineClassList"
      :schedule="assignments"
    />
  </section>
</template>

<script>
import Loader from "../components/Loader.vue";
import RegistrationsMixin from "../components/mixins/registrations.mixin";
import ScheduleList from "../components/ScheduleList.vue";
import {
  EVENT,
  eventTypes,
  ONLINE_CLASS
} from "../helpers/classes-events.helpers";

export default {
  name: "RegistrationsList",

  mixins: [RegistrationsMixin],

  components: { Loader, ScheduleList },

  props: {
    listType: {
      type: String,
      required: true,
      validate: eventTypes.includes
    }
  },

  computed: {
    assignments() {
      const { isEventsList, assignedClasses, assignedEvents } = this;
      return isEventsList ? assignedEvents : assignedClasses;
    },

    isEventsList() {
      return this.listType === EVENT;
    },

    isOnlineClassList() {
      return this.listType === ONLINE_CLASS;
    },

    schedule() {
      const { isEventsList, classRegistrations, eventRegistrations } = this;
      return isEventsList ? eventRegistrations : classRegistrations;
    }
  },

  mounted() {
    this.loading = false;
  }
};
</script>

<style lang="scss" scoped>
.registrations-list {
  width: $parent;
}
</style>
