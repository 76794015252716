import apiConfig, {
  Cookies,
  uploadFile,
  WriteUserCookieFallback
} from "../network";
import User from "./user.model";
import {
  ADMIN,
  TEACHER,
  isSuperAdmin,
  isSchedulerAdmin,
  isTeacher,
  PRACTITIONER,
  SCHEDULER_ADMIN,
  roles,
  SUPER_ADMIN
} from "./permissions";
import {
  currentTimeUTC,
  dateTimeForServer
} from "../helpers/date-time.helpers";

/* Data fetch */
/**
 * Begin the "forgot password" user flow
 * @param {object} params Request params
 * @param {string} params.email User email
 */
export async function beginforgotPassword(params) {
  const { status } = await apiConfig.forgotPassword(params);
  return { status };
}

/**
 * Begin the "forgot password" user flow
 * @param {object} params Request params
 * @param {string} params.email User email
 * @param {string} params.password New password
 */
export async function resetPassword(params) {
  const { user, message } = await apiConfig.resetPassword(params);
  if (!user) throw new Error(message);
  else return { user, message };
}

/**
 * Validate a "forgot password" token. User should receive this via email
 * once the "forgot password" flow has been initiated.
 * @param {object} p Request params
 * @param {string} p.email User `email`
 * @param {string} p.token "Reset password" token (received via email)
 */
export async function validateResetToken(p) {
  const { status, message, record } = await apiConfig.validateResetToken(p);
  if (message) throw new Error(message);
  else return { status, record };
}

async function attachUserImage(user, imageData) {
  const { imageFile, fileExtension } = imageData || {};
  if (!imageFile || !fileExtension || !isTeacher(user))
    return Promise.resolve(user);

  const name = user.fullName;
  const profileImg = await uploadUserImage({ imageFile, fileExtension, name });
  return { ...user, ...profileImg };
}

export async function createUser(data, imageData) {
  const user = User(data);
  const parallel = [attachUserImage(user, imageData)];
  if (isSchedulerAdmin(user)) parallel.push(unsetSchedulerAdmin());

  const [userImg] = await Promise.all(parallel);
  const res = await apiConfig.users.createUser({ ...user, ...userImg });
  if (res.message) throw res;
  return res;
}

/**
 * Delete a User from the MVP Users db
 * @param {number} userId User to delete
 */
export async function deleteUser(userId) {
  return await apiConfig.users.deleteUser({ userId });
}

async function unsetSchedulerAdmin() {
  const schedulers = await getSchedulerAdmins();
  const convertToAdmin = s => updateUser(User({ ...s, role: ADMIN }));
  return Promise.all(schedulers.map(convertToAdmin));
}

export async function getActiveUser() {
  const user = JSON.parse(decodeURIComponent(Cookies.get("mvp_user") || "{}"));
  return user && user.id
    ? await getUserById(user.id).then(user => {
        WriteUserCookieFallback({
          data: {
            user: {
              id: user.id,
              userName: user.userName,
              firstName: user.firstName,
              lastName: user.lastName,
              role: user.role
            }
          }
        });

        return user;
      })
    : null;
}

export async function getTeachers() {
  const [teachers, admins, superAdmins] = await Promise.all([
    getUsersByRole({ role: TEACHER }),
    getUsersByRole({ role: ADMIN }),
    getUsersByRole({ role: SUPER_ADMIN })
  ]);

  return [...teachers, ...admins, ...superAdmins];
}

export async function getPractitioners() {
  const [practitioners, superAdmins] = await Promise.all([
    getUsersByRole({ role: PRACTITIONER }),
    getUsersByRole({ role: SUPER_ADMIN })
  ]);
  return [...practitioners, superAdmins];
}

export async function getSchedulerAdmins() {
  return getUsersByRole({ role: SCHEDULER_ADMIN });
}

async function getUsersByRole(role) {
  const { data } = await apiConfig.users.getUsersByRole(role);
  return data.map(User);
}

export async function getUserById(userId) {
  const { data } = await apiConfig.users.getUserById({ userId });
  return User(data);
}

export async function getAllUsers(role) {
  const { data } = await apiConfig.users.getAllUsers();
  if (isSuperAdmin({ role })) return data.map(User);

  const rank = roles.findIndex(r => r === role);
  const filteredRoles = roles.slice(rank, roles.length);
  const canViewRole = ({ role: r }) => filteredRoles.includes(r);
  return data.filter(canViewRole).map(User);
}

export async function searchUsers(role, queryString) {
  const { data } = await apiConfig.users.searchUsers({ queryString });
  if (isSuperAdmin({ role })) return data.map(User);

  const rank = roles.findIndex(r => r === role);
  const filteredRoles = roles.slice(rank, roles.length);
  const canViewRole = ({ role: r }) => filteredRoles.includes(r);
  return data.filter(canViewRole).map(User);
}

export async function login(username, password) {
  return await apiConfig
    .login({ username, password })
    .then(WriteUserCookieFallback)
    .then(getActiveUser);
}

export async function logout() {
  await apiConfig.logout();
  localStorage.clear();
  return Promise.resolve(true);
}

export async function refreshToken() {
  await apiConfig.refreshToken().then(WriteUserCookieFallback);
  return getActiveUser();
}

export async function registerUser(user) {
  return apiConfig.users.registerUser(user);
}

/**
 * Calculates and sets a `subscriptionEnd` date. This will confer full membership
 * options on the target `User` until the `subscriptionEnd` date.
 * @param {User} user Target `User` object
 * @param {object} endDateOptions Options for calculating membership end-datae
 * @param {object} endDateOptions.days Length of membership in days
 * @param {object} endDateOptions.weeks Length of membership in weeks
 * @param {object} endDateOptions.months Length of membership in months
 */
export async function startLimitedMembership(user, endDateOptions) {
  const endDate = currentTimeUTC()
    .set({ minute: 0, second: 0, millisecond: 0 })
    .plus(endDateOptions);
  const trialEndDate = dateTimeForServer(endDate.toISO());
  return updateUser({ ...user, trialEndDate });
}

export async function updateUser(params, imageData, notify = s => s) {
  const user = User(params);
  const parallel = [attachUserImage(user, imageData)];
  if (isSchedulerAdmin(user)) parallel.push(unsetSchedulerAdmin());
  notify("Uploading image ...");
  const [userWithImg] = await Promise.all(parallel);
  notify("Updating user ...");
  const { message } = await apiConfig.users.updateUser(userWithImg);
  if (message === "success") return getActiveUser();
  throw new Error(message);
}

export async function uploadUserImage(formData) {
  const { imageFile, fileExtension } = formData;
  const userImage = await uploadFile(imageFile, {
    fileNameRef: formData.name || formData.fullName,
    fileContext: "user-profile",
    fileExtension
  });
  return { userImage };
}
