/* eslint-disable no-undef */

import { METHODS } from "../api.config";

/** Endpoints for `Stripe` -related events */
export default {
  createCustomer: {
    group: "billing",
    url: () => `${API_URL}/billing/customer`,
    method: METHODS.POST
  },
  createDonationIntent: {
    group: "billing",
    url: () => `${API_URL}/billing/donations`,
    method: METHODS.POST
  },
  createPaymentIntent: {
    group: "billing",
    url: () => `${API_URL}/billing/intent`,
    method: METHODS.POST
  },
  createSubscription: {
    group: "billing",
    url: () => `${API_URL}/billing/subscriptions`,
    method: METHODS.POST
  },
  createPortalSession: {
    group: "billing",
    url: () => `${API_URL}/billing/subscriptions/manage`,
    method: METHODS.POST
  },
  cancelSubscription: {
    group: "billing",
    url: () => `${API_URL}/billing/subscriptions`,
    method: METHODS.DELETE
  },
  getProductById: {
    group: "billing",
    url: ({ productId }) => `${API_URL}/billing/products/${productId}`
  },
  createStripeProduct: {
    group: "billing",
    url: () => `${API_URL}/billing/products`,
    method: METHODS.POST
  },
  listProducts: {
    group: "billing",
    url: () => `${API_URL}/billing/products`
  },
  listMembershipProducts: {
    group: "billing",
    url: () => `${API_URL}/billing/products?membership=1`
  }
};
