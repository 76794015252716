/**
 * Returns an object representing an app user.
 * @param {object} data Data to hydrate the `User` instance
 * @param {string} data.topic Meeting title
 * @param {string} data.agenda Meeting description
 * @param {string} data.type Zoom docs: type [ (1)="Instant mtg", (2)="Scheduled mtg" ]
 * @param {string} data.start_time Zoom docs: `yyyy-MM-ddTHH:mm:ss`. Required with `timezone`
 * @param {string} data.timezone Timezone (TZ database name e.g. `America/Los_Angeles`)
 * @param {string} data.duration Meeting duration (in `minutes` as integer)
 * @param {string} data.endDate Meeting End Date
 * @param {string} data.schedule_for (Optional) account user on whose behalf meeting is being scheduled
 * @param {string} data.password (Optional) Meeting passcode (up to 10 chars)
 * @param {object} data.settings Additional (host, user) meeting settings
 */
export default function ZoomMeeting(data = {}) {
  let dayOfWeek = new Date(data.start_time);

  const meetingData = {
    topic: data.topic || null,
    type: data.type || 2,
    start_time: new Date(data.start_time),
    timezone: data.timezone || null,
    duration: data.duration || 60,
    agenda: data.agenda || null,
    recurrence: {
      type: 2, //weekly
      repeat_interval: 1, //weekly max is 12 weeks
      weekly_days: new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
        dayOfWeek
      ),
      end_date_time: new Date(data.endDate)
    }
  };

  const dataSettings = data.settings || {};
  const settings = {
    /* (Zoom docs) approval_type: 1 => [host will] Manually Approve [participant entry] */
    approval_type: 1,
    host_video: dataSettings.host_video || true,
    join_before_host: dataSettings.join_before_host || false,
    mute_upon_entry: dataSettings.mute_upon_entry || true,
    participant_video: dataSettings.participant_video || false,
    /* (Zoom docs) Personal meeting id (pmi): scheduled, instant, recurring meetings only */
    use_pmi: dataSettings.use_pmi || false
  };

  if (data.password) meetingData.password = data.password;
  else settings.waiting_room = true;

  meetingData.settings = settings;
  return meetingData;
}

/**
 * Return a `ZoomUser` represenging an Org user with a zoom account
 * @param {object} data Zoom data
 * @returns {object} `ZoomUser` object
 */
export function ZoomUser(data = {}) {
  const type = data.type || 1;
  const { first_name, last_name } = data;
  const zoomUser = {
    // personal meeting ID
    email: data.email || null,
    firstName: first_name || null,
    lastName: last_name || null,
    personalMeetingId: data.pmi || null,
    timezone: data.timezone || null,
    type,
    userImage: data.pic_url || null,
    verified: data.verified || 0,

    // computed properties
    get fullName() {
      return `${first_name} ${last_name}`;
    },

    get typeDescription() {
      const desc = [null, "Basic", "Licensed", "On-Premise"];
      return desc[type] || desc[0];
    }
  };

  if (data.id) zoomUser.id = data.id;

  return zoomUser;
}

/**
 * Return a `ZoomParticipant` representing a zoom meeting attendee
 * @param {object} data Zoom data
 * @returns {object} `ZoomUser` object
 */
export function ZoomParticipant(data = {}) {
  const zoomUser = {
    // personal meeting ID
    email: data.user_email || null,
    name: data.name || null,

    // computed properties
    get fullName() {
      return name;
    }
  };

  if (data.id) zoomUser.id = data.id;

  return zoomUser;
}
