/* eslint-disable no-undef */
import { DateTime } from "luxon";
import { loadStripe } from "@stripe/stripe-js";
import { login, logout, refreshToken, getActiveUser } from "./models/user";
import { getUserRegistrations } from "./models/registrations";
import state from "./state";
import { Cookies } from "./network";
import { isAdminOrTeacher } from "./models/permissions";
import { currentTimeUTC } from "./helpers/date-time.helpers";

// * Logout if inactive for 5 (five) minutes. Reset when user does anything.
const INACTIVITY = 300000;
// * Refresh user token every 2 minutes unless logged out
const TOKEN = 120000;
// Reference object for running session
const TIMEOUTS = { INACTIVITY: INACTIVITY, TOKEN: TOKEN };

const EVENTS = [
  "keydown",
  "keypress",
  "mousemove",
  "mousedown",
  "scroll",
  "touchmove",
  "touchend",
  "orientationchange"
];

/**
 * The `Session Manager` is a singleton that manages user authentication state.
 * It uses the app's `APIConfig` instance to make auth (login/logout) requests,
 * and writes (but does not subscribe) to the `ApplicationState` singleton instance.
 */
const session = {
  inactivityTimer: null,

  refreshTokenTimer: null,

  started: false,

  /** Assert that a user is currently logged in */
  get isLoggedIn() {
    return getTokenExp() > 0;
  },

  /**
   * The "startup" function; stores active `user` in state and begins (inactivity, jwt) timers
   * @param {object} updates An object whose keys are state properties in the
   * current `application state` instance
   * @param {object} updates.user The currently-active user
   * @returns {object}
   */
  hydrate(updates) {
    // remove legacy cookie
    const token_exp = Cookies.get("token_exp");
    if (token_exp) Cookies.erase("token_exp");
    // end legacy

    // run token and inactivity timers
    if (updates.user) {
      resetTokenExpiration();
      resetTokenTimer();
      resetActivityTimer();

      // Reset activity timer when user interacts with app
      EVENTS.forEach(e => window.addEventListener(e, resetActivityTimer));
    }

    // Remove timers when window unload is triggered
    window.addEventListener("beforeunload", session.stop);

    // Update state and return the active user (if needed by caller)
    session.started = true;
    state.multiple({ ...updates, sessionStarted: true });
    return updates.user;
  },

  /**
   * Logs user in and begins UI session
   * @param {string} username Username
   * @param {string} password User password
   */
  async login(username, password) {
    try {
      const updates = {};
      const user = await login(username, password);
      const params = registrationParams(user);
      updates.userRegistrations = await getUserRegistrations(params);
      updates.user = user;
      // Start up the session
      return session.hydrate(updates);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /** Log out the active user and clear from application state */
  async logout() {
    try {
      await logout();
    } catch (e) {
      // e.preventDefault();
    } finally {
      session.stop();
    }

    return Promise.resolve(true);
  },

  /**
   * Refresh JWT token from server and set time of next refresh. Ignore failure
   * when token has not yet expired.
   */
  async refreshToken() {
    // Attempt only if logged in
    try {
      logInfo("* refreshing token");
      await refreshToken();
      resetTokenExpiration();
      resetTokenTimer();
      return true;
    } catch (error) {
      logInfo(`\n\t\t-- [ERR] token refresh: ${JSON.stringify(error)}`);
    }

    // Return a 'success' notification (regardless of outcome)
    return true;
  },

  refreshActivityTimer() {
    if (session.started) return resetActivityTimer();
  },

  async start() {
    if (session.started) return state.getState().user;

    try {
      // Attempt token refresh if there's a cookie user
      if (session.isLoggedIn) {
        await session.refreshToken();
      }

      // Get logged-in user (if available) and stripe library
      const [stripe, user] = await Promise.all([
        loadStripe(STRIPE_API_KEY),
        getActiveUser()
      ]);
      const opts = { stripe, user };

      // preload user registrations into state
      if (user) {
        const params = registrationParams(user);
        opts.userRegistrations = await getUserRegistrations(params);
      }

      session.hydrate(opts);
      return user;
    } catch (error) {
      // No active user; await login
      logInfo({ error }, "WARN");
      return Promise.reject(error);
    }
  },

  stop() {
    clearTimers();
    /* remove activity timer listeners */
    EVENTS.forEach(e => window.removeEventListener(e, resetActivityTimer));
    /* remove event listener for this ("stop") method */
    window.removeEventListener("beforeunload", session.stop);
    session.started = false;
    /* reset state : retain and reload listeners to keep long-lived UI elements plugged in. */
    const { stripe } = state.getState();
    const subscribers = [...state.subscribers];
    state.reset();
    state.subscribers = [...subscribers];
    state.multiple({ stripe, sessionStarted: false });
  }
};

export default session;

/* Helpers/Private funcs */
/** Clear all timers attached to `window` object */
function clearTimers() {
  logInfo(`Clearing All Timers"`);
  maybeClearTimer("inactivityTimer");
  maybeClearTimer("refreshTokenTimer");
}

/**
 * Get difference between now and token expiration date/time in milliseconds
 * @returns {number}
 */
function getTokenExp() {
  const jwtExp = Cookies.get("auth_until");

  // If JWT, return the diff. between expiration and now in milliseconds
  if (jwtExp) {
    const jwtExpDec = decodeURIComponent(jwtExp);
    const next = DateTime.fromFormat(jwtExpDec, "y'-'MM'-'dd T", {
      zone: "utc"
    });
    return Math.max(next.diffNow().toMillis(), 0);
  }

  return 0;
}

/** Reset cookie-stored token expiration and refresh timeout duration */
function resetTokenExpiration() {
  const refresh = getTokenExp();
  TIMEOUTS.TOKEN = refresh <= 0 ? TOKEN : refresh;
}

/** Logout if inactive for 5 (five) minutes. Reset when user does anything. */
function resetActivityTimer() {
  maybeClearTimer("inactivityTimer");
  runTimer("inactivityTimer", session.logout, TIMEOUTS.INACTIVITY);
}

/** Refresh user token every 2 (two) minutes unless logged out */
function resetTokenTimer() {
  maybeClearTimer("refreshTokenTimer");
  runTimer("refreshTokenTimer", session.refreshToken, TIMEOUTS.TOKEN);

  // dev mode only
  if (NODE_ENV === "development") {
    const next = currentTimeUTC().plus({ milliseconds: TIMEOUTS.TOKEN });
    const nextTime = next.setZone(DateTime.local().zoneName).toFormat("T");
    logInfo(`\n\t\t -- Next token refresh ${next.toRelative()} at ${nextTime}`);
  }
}

/**
 * Run a specified timer in `session`
 * @param {string} timerName timer key in `session` object
 * @param {Function} callback Function to run at end of timeout
 * @param {number} duration Milliseconds to elapse before running `callback`
 */
function runTimer(timerName, callback, duration) {
  session[timerName] = window.setTimeout(callback, duration);
  logInfo(`Restarted "${timerName}"`);
}

/**
 * Clear a `window.setTimeout` timer if it exists
 * @param {string} name Name of timer key on `session`
 */
function maybeClearTimer(name) {
  if (session[name] === null) return;
  logInfo(`Clearing "${name}" (val ${session[name]})`);
  window.clearTimeout(session[name]);
  session[name] = null;
  logInfo(`Cleared "${name}"`);
}

function registrationParams(user) {
  const params = { userId: user.id };
  if (isAdminOrTeacher(user)) params.teacherId = params.userId;
  return params;
}

function logInfo(info, level = "INFO") {
  if (level === "WARN") return console.warn(info);
  else if (NODE_ENV !== "development") return;

  // Filter "inactivityTimer" resets
  const matchPattern = pattern => new RegExp(pattern, "gi").test(info);
  if (matchPattern("inactivityTimer")) return;

  console.info(`%c[SESSION]: * ${info}`, "color:green");
}
