import apiConfig from "@network";
import { dateTimeForServer } from "../helpers/date-time.helpers";
import { uploadFile } from "../network";
import MVPEvent, { EventProduct } from "./event.model";
import { createStripeProduct, getProductById } from "./stripe";
import { scheduleMeeting } from "./zoom";

/* Data fetchers */

export async function createOrUpdateEvent(form, srcEvent) {
  // Additional mtg details
  const data = { ...form };
  const { imageUrl, zoomLinks, product } = await updateEventDetails(data);
  const eventData = MVPEvent({ ...form, ...imageUrl, ...zoomLinks });

  // Attach product id
  if (product.id) eventData.productId = product.id;

  // Attach zoom meeting passcode to event description if present
  if (zoomLinks.zoomMeetingPasscode) {
    const { zoomMeetingPasscode: pwd } = zoomLinks;
    eventData.description = `${eventData.description}\n\nMeeting passcode: ${pwd}`;
  }

  // Update event date to UTC
  const {
    scheduledDate: date,
    scheduledEndDate: end,
    scheduledTimeZone: zone
  } = eventData;
  eventData.scheduledDate = dateTimeForServer(date, zone);

  if (end) eventData.scheduledEndDate = dateTimeForServer(end, zone);

  // Teachers: attach id if teacher data exists, or don't update the key
  ["assignedTeacher", "backupTeacher"].forEach(key => {
    const teacher = form[key] || {};
    if (teacher.id) eventData[key] = teacher.id;
    else if (typeof teacher !== "number") delete eventData[key];
  });
  // Attach zoom occurrences if they are present
  if (zoomLinks.occurrences) {
    eventData.occurrences = zoomLinks.occurrences;
  }

  // Create (if no source/reference event data) or update existing event
  const response =
    srcEvent === null
      ? await apiConfig.events.createEvent(eventData).then(MVPEvent)
      : await updateEvent(eventData);

  return response;
}

/**
 * Delete an `Event` (or 'Online Class') and any attached zoom meetings
 * @param {object} event Target `Event` to delete
 * @param {number} event.id `Event` id
 * @param {number} event.zoomMeetingId (Optional) `Event` zoom meeting id
 */
export async function deleteEvent(event) {
  const { id, zoomMeetingId: meetingId } = event;
  const promises = [apiConfig.events.deleteEvent({ eventId: id })];
  // delete zoom meeting if one is attached
  if (meetingId) {
    promises.push(apiConfig.zoom.deleteZoomMeeting({ meetingId }));
  }
  const [eventDeleted, meetingDeleted] = await Promise.all(promises);
  return { eventDeleted, meetingDeleted };
}

/**
 * Upload image, schedule zoom meeting, and/or create a Stripe product
 * for an `Event` if needed
 * @param {object} data src data for event
 * @param {object} data.imageFile image data
 * @param {boolean} data.createZoom schedule a zoom meeting for event
 * @param {boolean} data.createProduct create Stripe product for event
 */
async function updateEventDetails(data) {
  const detailsPromises = [];
  const { imageFile, createZoom, createProduct } = data;
  detailsPromises.push(
    imageFile ? uploadImage(data) : Promise.resolve({}),
    createZoom ? scheduleMeeting(data) : Promise.resolve({}),
    createProduct
      ? createStripeProduct(
          EventProduct({
            name: data.name,
            active: !data.disableProduct,
            description: data.shortDescription,
            currency: data.currency,
            amount: data.eventPrice
          })
        )
      : Promise.resolve({ id: data.productId || null })
  );

  const [imageUrl, zoomLinks, product] = await Promise.all(detailsPromises);

  return { imageUrl, zoomLinks, product };
}

async function updateEvent(event) {
  const { message } = await apiConfig.events.updateEvent(event);
  return message === "success" ? await getEventById(event.id) : message;
}

export async function getAllPublicClasses() {
  const { data: classes } = await apiConfig.events.getAllPublicClasses();
  return classes.filter(hideCompletedEvents).map(MVPEvent);
}

export async function getAllPublicEvents() {
  const { data } = await apiConfig.events.getAllPublicEvents();
  return data.filter(hideCompletedEvents).map(MVPEvent);
}

export async function getEventById(eventId) {
  const { data } = await apiConfig.events.getEventById({ eventId });
  const product = data.productId ? await getProductById(data.productId) : {};
  const event = MVPEvent(data);
  event.product = product;

  return event;
}

export async function uploadImage(formData) {
  const { name, imageFile, fileExtension } = formData;
  const eventImage = await uploadFile(imageFile, {
    fileNameRef: name,
    fileContext: "event-cover",
    fileExtension
  });
  return { eventImage };
}

/* Helper */

function hideCompletedEvents({ eventStatus }) {
  return eventStatus !== "Completed";
}
