/**
 * Indicates a future event attendance as either host or student
 * @param {object} data
 * @returns {EventRegistration}
 */
export default function EventRegistration(data = {}) {
  return {
    id: data.id || null,
    classId: data.classId || null,
    createdAt: data.createdAt || null,
    deletedAt: data.deletedAt || null,
    eventId: data.eventId || null,
    updatedAt: data.updatedAt || null,
    userId: data.userId || null,
    user: data.user || null
  };
}
