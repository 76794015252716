import { Capacitor } from "@capacitor/core";

/**
 * A `Cookie Helper` for reading/writing cookies via friendly `helper.get/set` methods
 */
const Cookies = {
  /**
   * Sets cookie with new data. Does not return data will only update the window
   *
   * @param {string} name
   * @param {string|number} value
   * @param {number} days - expiration time for cookie.
   * @default  days - 30
   *
   * @return {void}
   */
  set(name, value, days = 30) {
    // backup
    if (Capacitor.isNative) {
      return localStorage.setItem(name, value);
    } else if (["access_token", "auth_until", "mvp_user"].includes(name)) {
      return;
    }

    let expDate;

    if (days) {
      if (days === -1) {
        expDate = "Thu, 01 Jan 1970 00:00:00 GMT";
      } else {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expDate = `${date.toGMTString()}`;
      }
    }

    const { host } = window.location;
    const expires = expDate ? `expires=${expDate}` : "";

    if (host.split(".").length === 1) {
      document.cookie = `${name}=${value}; ${expires}; path=/; domain=.${host}`;
    } else {
      const domainParts = host.split(".");
      domainParts.shift();
      const domain =
        Cookies.get(name) == null || Cookies.get(name) !== value
          ? `.${host}`
          : `.${domainParts.join(".")}`;

      document.cookie = `${name}=${value}${expires}; path=/; domain=${domain}`;
    }
  },

  /**
   * Gets the cookie from document
   *
   * @param {string} name
   * @return {string|null}
   */
  get(name) {
    if (Capacitor.isNative) {
      const stored = localStorage.getItem(name);
      return stored;
    }

    const cookies = document.cookie.split(";");
    const key = `${name}=`;

    for (let i = 0; i < cookies.length; i += 1) {
      let cookie = cookies[i];
      const { length: cookieLength } = cookie;

      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1, cookieLength);
      }

      if (cookie.indexOf(key) === 0) {
        return cookie.substring(key.length, cookieLength);
      }
    }

    return null;
  },

  /**
   * Remove cookie and set expiration to -1 day. Browser will
   * automatically remove expired cookie on next check.
   *
   * @param {string} name
   */
  erase(name) {
    Cookies.set(name, "", -1);
  },

  eraseAll() {
    if (document.cookie) {
      const documentCookies = document.cookie;
      documentCookies.split(";").forEach(segment => {
        const cookie = segment.split("=")[0];
        return Cookies.erase(cookie);
      });
    }
  }
};

export default Cookies;
