<template>
  <article :class="`${titleClass} tile-list-item column primary-light--text`">
    <div
      v-if="backgroundImage"
      class="background-image"
      :style="`background-image: url('${backgroundImage}')`"
    />

    <span class="list-item--title">{{ title }}</span>

    <span v-if="description" class="description">{{ description }}</span>
    <!-- External Link or Button/Link with 'OnClick' handler -->
    <a
      v-if="isExternalLink || button"
      :class="buttonClass"
      :title="title"
      target="_blank"
      rel="noopener nofollow"
      role="button"
      @click.prevent="button && $emit('list-item-click')"
    >
      <span>{{ actionText || `Go to ${title}` }}</span>
      <hr class="divider divider--vertical" />
      <i class="fas fa-arrow-right"></i>
    </a>
    <!-- Internal (App route) Link -->
    <router-link v-else :class="buttonClass" :title="title" :to="href">
      <span>{{ actionText || `Go to ${title}` }}</span>
      <hr class="divider divider--vertical" />
      <i class="fas fa-arrow-right"></i>
    </router-link>
  </article>
</template>

<script>
export default {
  name: "TileListItem",
  props: {
    actionText: String,
    backgroundImage: String,
    button: Boolean,
    clickable: Boolean,
    description: String,
    glyph: String,
    href: [String, Object],
    isExternalLink: Boolean,
    selected: Boolean,
    title: { type: String, required: true }
  },

  computed: {
    buttonClass() {
      return { "button inline": true, active: this.selected };
    },

    titleClass() {
      const titles = this.title
        .replace(new RegExp(/(Upcoming|Online)\s/), "")
        .split(" ");

      return titles.length === 1 ? this.title : titles[0];
    }
  }
};
</script>

<style lang="scss">
.tile-list-item {
  @include flex-center();
  border: $xxs solid #fff;
  outline: 1px solid $grey-dark;
  text-decoration: none;
  transition: background-color 150ms linear;
  height: 26rem;
  width: 24rem;
  z-index: 5;

  &::after {
    background-position: center;
    background-size: cover;
    background-color: #fff;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.3;
    height: $parent;
    width: $parent;
    z-index: -1;
  }

  &:hover {
    @include elevated($tiny);
    background-color: #ffffff99;
  }

  > .list-item {
    @include center-text();
    height: $parent;
    width: $parent;
    padding: 0;
  }

  .background-image {
    @include fill-parent();
    background-size: cover;
    background-position: center;
    left: 0;
    opacity: 0.55;
    position: absolute;
    top: 0;
  }

  .description {
    padding-left: $sm;
    padding-right: $sm;
    padding-top: $sm;
  }

  .list-item--title {
    @include h4;
  }
}

@media screen and (max-width: 600px) {
  .tile-list-item {
    margin-bottom: $sm;
  }
}
</style>
