import createState from "@jackcom/raphsducks";

const state = createState({
  /* Stripe Product Id for global modal to fetch */
  activeProductId: null,
  /* List of Videos available to logged-in (non-admin-context) users */
  libraryPlaylists: [],
  /* Notify user to refresh page cache on new app content delivery */
  newContentAvailable: false,
  /* New Service Worker registration (when new remote files are detected) */
  serviceWorkerRegistration: null,
  /* Assert session has started with an active user */
  sessionStarted: false,
  /* Show hide global "Join MVP/Buy Product" modal */
  showProductModal: false,
  /* `Stripe` library reference (created on Session instantiation) */
  stripe: null,
  /* List of stripe products */
  stripeProducts: [],
  /* List of section sub-routes/actions to pin above and below page content */
  subroutes: [],
  /* Active user reference */
  user: null,
  /* List of events/classes that active user has registered to attend */
  userRegistrations: {}
});

export default state;
