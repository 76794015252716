<template>
  <footer id="app-footer">
    <div class="content">
      <div class="legal-links footer-column no-shrink">
        <h3 class="section-title">More Links</h3>

        <div class="footer-column__content">
          <a
            v-for="link in legalLinks"
            :key="link.name"
            :href="link.href"
            :title="link.name"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ link.name }}
          </a>
        </div>
      </div>

      <div class="social-links footer-column no-shrink">
        <h3 class="section-title">Follow us</h3>

        <div class="footer-column__content">
          <a
            v-for="link in socialLinks"
            :key="link.name"
            :href="link.href"
            :title="`Follow on ${link.name}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i :class="link.icon"></i>
          </a>
        </div>
      </div>
    </div>

    <aside class="version-stamp">
      <span>{{ `v.${appVersion}` }}</span>
    </aside>
  </footer>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: "AppFooter",

  computed: {
    appVersion() {
      return APP_VERSION;
    },
    legalLinks() {
      return [
        {
          name: "Privacy Policy",
          href: "https://www.myvinyasapractice.com/privacy-policy/"
        },
        {
          name: "Terms & Conditions",
          href: "https://www.myvinyasapractice.com/terms-and-conditions"
        },
        {
          name: "Refund Policy",
          href: "https://www.myvinyasapractice.com/refund-policy/"
        }
      ];
    },
    socialLinks() {
      return [
        {
          name: "Pinterest",
          href: "https://www.pinterest.com/anothermommy38/",
          icon: "fab fa-pinterest-p"
        },
        {
          name: "Facebook",
          href: "https://www.facebook.com/myvinyasapractice/",
          icon: "fab fa-facebook-f"
        },
        {
          name: "Instagram",
          href: "https://www.instagram.com/myvinyasapractice/",
          icon: "fab fa-instagram"
        },
        {
          name: "Youtube",
          href: "https://www.youtube.com/c/MyVinyasaPractice",
          icon: "fab fa-youtube"
        }
      ];
    }
  }
};
</script>

<style lang="scss" src="./AppFooter.scss"></style>
