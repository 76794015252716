import Vue from "vue";
import DateTimePicker from "vue-vanilla-datetime-picker";
import { EditorContent, EditorMenuBar } from "tiptap";
import "./registerServiceWorker";
import router from "./router";
import App from "./App.vue";
import "./main.scss";

Vue.config.productionTip = false;

Vue.component("datepicker", DateTimePicker);
Vue.component("EditorContent", EditorContent);
Vue.component("EditorMenuBar", EditorMenuBar);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
