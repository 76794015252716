<template>
  <div class="custom-legend wrap" :class="{ active: showingList }">
    <!-- Label -->

    <!-- "Hide" "Button" -->
    <button class="toggle-list" @click.stop="showLegend">
      <span v-if="showingList"
        >Hide <i class="fas fa-eye-slash item error-light--text"
      /></span>
      <strong v-else class="accent--text">Legend</strong>
    </button>

    <hr class="divider divider--vertical" />
    <!-- Legend Glyphs (3 items or less) -->
    <template v-if="showingList && items.length <= inlineThreshold">
      <span
        v-for="(item, i) in items"
        :key="item.text"
        class="item"
        :style="`animation-delay:${i * 120}ms;`"
      >
        <i :class="item.icon"></i>&nbsp; {{ item.text }}&nbsp;
      </span>
    </template>

    <!-- Legend Glyphs (more than 3 items) -->
    <ul
      v-if="showingList && items.length > inlineThreshold"
      class="list legend slide-down-fade-in"
    >
      <li class="item" v-for="item in items" :key="item.text">
        <i :class="item.icon"></i>&nbsp; {{ item.text }}&nbsp;
      </li>
    </ul>
  </div>
</template>

<script>
import KeyboardEventsMixin from "./mixins/keyboard-events.mixin";

export default {
  name: "CustomLegend",

  mixins: [KeyboardEventsMixin],

  props: { items: { type: Array, required: true } },

  data: () => ({
    inlineThreshold: 2,
    listenersActive: false,
    showingList: false
  }),

  methods: {
    cancelActiveState() {
      this.exitActiveState();
    },

    exitActiveState() {
      this.showingList = false;
      return this.detachListeners();
    },

    showLegend() {
      if (this.showingList) return this.exitActiveState();
      else {
        this.showingList = true;
        this.attachListeners();
      }
    }
  }
};
</script>

<style lang="scss">
.custom-legend {
  @include slide-down-fade-in;
  animation-duration: 300ms;
  display: inline-flex;
  font-size: smaller;
  padding-bottom: $xs;
  padding-top: $xs;
  margin-left: $xs;

  &.active {
    @include rounded;
    background: white;
  }

  .legend.list {
    @include elevated;
    background-color: white;
    left: $xlg;
    list-style: none;
    max-width: 24rem;
    outline: 1px solid $primary-light;
    position: absolute;
    top: $lg;
    width: 16rem;
    z-index: 699;
  }

  .item {
    margin-left: $xxs;
  }

  li.item {
    border-bottom: 1px solid $grey-white;
    line-height: $lg;
  }

  .toggle-list {
    @include heading-font;
    box-shadow: none;
    color: $accent;
    font-size: $xs;
    height: $md;
    letter-spacing: $xtiny;
    line-height: $md;
    margin: 0;
    padding: 0 $xxxs;
  }
}

@media screen and (max-width: 768px) {
  .custom-legend {
    .list.legend {
      @include elevated(8px);
      @include slide-up-fade-in();
      top: auto;
      bottom: 0;
      left: 0;
      max-height: 50vh;
      max-width: $screen-width;
      overflow-y: auto;
      position: fixed;
      width: $screen-width;
      z-index: 99;
    }
  }
}
</style>
