/**
 * Handles behavior for updating application files when a new deployment is detected.
 */
const AppUpdateMixin = {
  // refresh variables
  data: () => ({ refreshing: false }),

  created() {
    // Prevent multiple refreshes
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        "controllerchange",
        this.triggerReload
      );
    }
  },

  methods: {
    triggerReload() {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    }
  }
};

export default AppUpdateMixin;
