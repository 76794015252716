import { camelToSentence } from "../utilities";

/**
 * Generate an error messaage with list of missing-but-required fields in a form.
 * @param {object} form Form data
 * @param {string[]} requiredFields List of required form data fields
 * @param {string[]|undefined} optionalFields List of optional form data fields
 */
export function invalidFormMessage(form, requiredFields, optionalFields = []) {
  const missing = field => !form[field];
  const missingAll = f =>
    optionalFields.length
      ? !optionalFields.includes(f) && missing(f)
      : missing(f);

  const fields = requiredFields.filter(missingAll).map(camelToSentence);

  // conditionals/optional fields
  if (optionalFields.every(missing)) {
    const missingOptionals = optionalFields.map(camelToSentence).join(" OR ");
    fields.push(missingOptionals);
  }

  const prompt = "Some inputs are missing: ";
  return `${prompt} ${fields.join(", ")}`;
}

/**
 * Extract a list of fields (object properties) from `src`
 * @param {object} src source data for extraction
 * @param {string[]} requiredFields list of properties to extract from `src`
 */
export function extractFormFields(src = {}, requiredFields = []) {
  const merge = (agg, f) => mergeFormFields(agg, f, src);
  return requiredFields.reduce(merge, {});
}

/**
 * Array reducer: merge a list of fields into a single object-literal
 * @param {object} fields list of fields
 * @param {*} field current field in iteration
 * @param {*} src data source for copying
 */
export function mergeFormFields(fields, field, src) {
  return { ...fields, [field]: src[field] || null };
}
