<template>
  <nav class="data-action-menu route-content">
    <span
      :class="`button-wrapper ${linkClass(route.name)}`"
      v-for="route in routes"
      :key="route.name"
    >
      <button
        v-if="route.click"
        :class="linkClass(route.name)"
        @click.stop="route.click"
      >
        <i v-if="route.icon" :class="route.icon"></i>
        <span class="route-label"> &nbsp;{{ route.text }} </span>
      </button>

      <router-link
        v-else
        :class="linkClass(route.name)"
        :to="route.to || { name: route.name }"
      >
        <i v-if="route.icon" :class="route.icon"></i>
        <span class="route-label"> &nbsp;{{ routeName(route.name) }} </span>
      </router-link>
    </span>
  </nav>
</template>

<script>
import { routeName } from "@/models/permissions";

export default {
  name: "DataActionMenu",

  props: { routes: { type: Array, required: true } },

  methods: {
    activeLink(route) {
      return route === this.$route.name;
    },

    linkClass(route) {
      const btn = this.activeLink(route) ? "disabled" : "";
      return `h6 link button--outline ${btn}`.trim();
    },

    /* Exposed imports */
    routeName
  }
};
</script>

<style lang="scss" scoped>
.data-action-menu {
  @include flex-center;
  @include sticky(0);
  @include elevated(2px);
  background-color: white;
  margin: 0 auto;
  padding-bottom: 0;

  .button--outline {
    @include ellipsis;
    @include center-text;
    border: 0;
    font-size: $sm;
    letter-spacing: $xtiny;
    margin: 0;
    text-transform: initial;

    &:not(:first-of-type) {
      border-left: 1px solid $grey-light;
    }
  }

  .button-wrapper {
    padding-left: 0;
    padding-right: 0;

    > * {
      display: block;
      height: $parent;
      width: $parent;
    }
  }

  .disabled {
    color: $link;
  }
}

@media screen and (max-width: 600px) {
  .data-action-menu {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .route-label {
      font-size: $xs;
    }
  }
}

@media screen and (max-width: 480px) {
  .data-action-menu {
    .button--outline {
      @include flex-column;
    }
  }
}
</style>
