import { getCached } from "../utilities";

/**
 * Request Methods
 */
export const METHODS = {
  POST: "POST",
  GET: "GET",
  DELETE: "DELETE",
  PATCH: "PATCH",
  PUT: "PUT"
};

export default APIConfig;

/**
 * - Creates and configures a `Fetch` request using an APIRoute object
 * - Triggers the request and returns the JSON from the server
 * @param {object} routes A key-value store of server endpoints. Each key in
 * `routes` will become a method on the new `APIConfig` instance. The value of each
 * key will be a `RouteDefinition` with one or more of the following properties:
 *  - `acceptHeaders`: string | undefined;
 *  - `contentType`: string | undefined;
 *  - `url`: Function;
 *  - `authenticate`: boolean | undefined;
 *  - `method`: string | undefined
 * @param {object} globalErrorHandler An error handler to run when any network request
 * fails. The handler will receive the (`APIConfig` instance-) rejected promise as its
 * only argument. It should also return a promise (resolve/rejected per implementation needs).
 */
function APIConfig(
  routes /* : { [x: string]: RouteDefinition } */,
  globalErrorHandler = error => error
) {
  if (!routes) throw new Error("Missing routes");
  this.routes = routes;

  const routeKeys = Object.keys(routes);

  if (routeKeys.length === 0)
    throw new Error("APIConfig needs at least one valid route definition");

  // Append route keys to object so accessibe as APIConfig.route(params).then(...);
  Object.keys(routes).forEach(routeName => {
    const route = this.routes[routeName];
    const { group = null } = route;
    // Group route if key present (e.g. [getById, users] -> config.users.getByid vs
    // [getById] -> config.getById )
    if (group) {
      if (!this[group]) this[group] = {};

      this[group][routeName] = configureRoute(route, globalErrorHandler);
    } else {
      this[routeName] = configureRoute(route, globalErrorHandler);
    }
  });

  return this;
}

APIConfig.prototype.METHODS = METHODS;

function configureRoute(
  route /* : RouteDefinition */,
  globalErrorHandler = error => error
) {
  /**
   * Makes an http/s request `with` the supplied `params`. Enables the api
   * `configInstance.route(routeParams).with(requestParams)`
   * @param {object} params The request body contents. Anything that would be
   * passed to a `fetch` call (except the `url` for the request) goes here.
   * @param {string|undefined} params.token An optional bearer token for authenticating
   * with a remote server. Required if the `ConfiguredRoute` instance contains a
   * `authenticate: true` key/value.
   * @param {object|undefined} params.body (optional) request `body` [required for `post`
   * requests]
   */
  return function configuredRequest(params = {}) {
    // Get an object ready to make a request
    const method = route.method || METHODS.GET;
    let url = route.url(params);

    // Configure request
    let reqConfig = {
      method,
      // Allow for setting cookies from origin
      credentials: "include",
      // Prevent automatic following of redirect responses (303, 30x response code)
      redirect: route.redirect || "manual",
      // CORS request policy
      mode: route.mode || "cors"
    };

    if (route.contentType === "multipart/form-data") {
      reqConfig.headers = appendTokens({});
    } else {
      reqConfig.headers = configureReqHeaders(params, url);
    }

    // Configure request body
    if (method !== METHODS.GET) {
      let body = params.body || params;
      const { headers = {} } = reqConfig;
      reqConfig.body =
        headers && headers.Accept === "application/json"
          ? JSON.stringify(body)
          : body;
    }

    let fetchRequest = new Request(url, reqConfig);
    let responseCode = -1;
    const successResponse = { message: "success" };

    // Return fetch Promise
    return fetch(fetchRequest)
      .then(data => {
        responseCode = data.status;
        // If it has json, return json
        if (data.json) return data.json() || successResponse;
        // Safari apparently handles API "redirect" (303, 30x) responses very, very poorly;
        // We intercept the response and return something that doesn't kill the app.
        const isRedirectResponse = data.type === "opaqueredirect";
        // "DELETE" request doesn't return a body, so return "success" for that too
        const isDeleteResponse =
          method === METHODS.DELETE && responseCode < 400;
        if (isRedirectResponse || isDeleteResponse) return successResponse;
        // At this point, the response *better* have a body. Or else.

        return data || successResponse;
      })
      .then(onResponseFallback)
      .catch(onResponseFallback);

    function onResponseFallback(json) {
      // Check for API failures and reject response if response status error
      if (json.error) {
        return globalErrorHandler(Promise.reject(json));
      }

      if (responseCode > 400 || responseCode === -1) {
        // send configured request to error handler for retry
        const retryParams = { url, reqConfig };
        return globalErrorHandler(
          Promise.reject(json),
          responseCode,
          retryParams
        );
      }

      return Promise.resolve(json || successResponse);
    }
  };

  /**
   * Configure request headers. If `route.authenticate` is true, optionally inject an `Authorization: Bearer ...` header using an expected `token` key in `params`
   * @param {object} params request params
   * @param {string} url request url
   * @returns {object} header
   */
  function configureReqHeaders(params /* , url */) {
    let headers = {};
    if (route.headers) {
      // Static route header override
      headers = route.headers;
    } else {
      headers = {
        Accept: route.acceptHeaders || "application/json",
        "Content-Type": route.contentType || "application/json;charset=utf-8",
        // optional route header overrides/additions
        ...(route.mergeHeaders || {}),
        // optional request-level header overrides/additions
        ...(params.headers || {})
      };
    }

    return appendTokens(headers);
  }

  /**
   * Appends request headers
   * @param {object|undefined} headerData
   * @returns {{ Authorization: string, "refresh-token": string }} */
  function appendTokens(headerData) {
    let headersWithTokens = { ...headerData };
    // Inject access and refresh token (default override)
    const token = getCached("access_token");
    if (token) headersWithTokens.Authorization = `Bearer ${token}`;

    const refresh_token = getCached("refresh_token");
    if (refresh_token) headersWithTokens["refresh-token"] = refresh_token;

    return headersWithTokens;
  }
}
