import { DateTime } from "luxon";

export const utcDateTimeFormat = "yyyy-LL-dd'T'HH:mm:ss.SSS'Z'";

/**
 * Compare two Luxon `DateTime` objects
 * @param {string|DateTime} date1 Date for comparison basis
 * @param {string} zone Date zone (optional: defaults to local)
 */
export function assertDate(date1, zone) {
  const { zoneName } = DateTime.local();
  const zone1 = zone || zoneName;
  const d = utcFromISO(date1).setZone(zone1);

  return {
    /**
     * assert `date2` follows `date1`
     * @param {string|DateTime} date2 Target date for comparison
     * @param {*} zone2 Timezone for `date2`
     * @param {*} interval One of `day', `hour`, `minute`, etc.
     * @returns {boolean} assertion of statement `date1 is after date2`
     */
    isAfter: (date2, zone2 = "utc", interval = "day") => {
      const d1 = d.setZone(zone2);
      const d2 = utcFromISO(date2, zone2).setZone(zone1);
      return d1.get(interval) > d2.get(interval);
    },
    /**
     * assert `date2` precedes `date1`
     * @param {string|DateTime} date2 Target date for comparison
     * @param {*} zone2 Timezone for `date2`
     * @param {*} interval One of `day', `hour`, `minute`, etc.
     * @returns {boolean} assertion of statement `date1 is before date2`
     */
    isBefore: (date2, zone2 = "utc") => {
      const d1 = d.setZone(zone2);
      const d2 = utcFromISO(date2, zone2).setZone(zone1);
      return d1.toMillis() < d2.toMillis();
    },
    /**
     * assert `date2` is same day as `date1`
     * @param {string|DateTime} date2 Target date for comparison
     * @param {*} zone2 Timezone for `date2`
     * @param {*} interval One of `day', `hour`, `minute`, etc.
     * @returns {boolean} assertion of statement `date1 is same as date2`
     */
    isSame: (date2, zone2 = "utc", interval) => {
      const d2 = utcFromISO(date2, zone2).setZone(zone1);
      return ["month", "year", "day", interval].every(
        iv => d.get(iv) === d2.get(iv)
      );
    },
    /**
     * assert `date2` is the same as (or follows) `date1`
     * @param {string|DateTime} date2 Target date for comparison
     * @param {*} zone2 Timezone for `date2`
     * @returns {boolean} assertion of statement `date1 is same as (or after) date2`
     */
    isSameOrAfter: (date2, zone2 = "utc") => {
      const d1 = d.setZone(zone2);
      const d2 = utcFromISO(date2, zone2).setZone(zone1);
      return d1.toMillis() >= d2.toMillis();
    },
    /**
     *
     * @param {string|DateTime} date2 Target date for comparison
     * @param {*} zone2 Timezone for `date2`
     * @param {*} interval One of `day', `hour`, `minute`, etc.
     * @returns {boolean} assertion of statement `date1 is same as (or before) date2`
     */
    isSameOrBefore: (date2, zone2 = "utc") => {
      const d1 = d.setZone(zone2);
      const d2 = utcFromISO(date2, zone2).setZone(zone1);
      return d1.toMillis() <= d2.toMillis();
    }
  };
}

/**
 * Compare a Luxon `DateTime` object to current moment in UTC
 * @returns {{
 * isAfter: Function,
 * isBefore: Function,
 * isSame: Function,
 * isSameOrAfter: Function,
 * isSameOrBefore: Function,
 * }}
 */
export function assertNow() {
  const now = currentTimeUTC();
  return assertDate(now, now.zoneName);
}

/**
 * Get current time in UTC as Luxon DateTime
 * @returns {DateTime} current time as Luxon `DateTime` object
 */
export function currentTimeUTC() {
  return DateTime.now().toUTC();
}

/**
 * Generate a "to relative" string either in days or hours, depending on whether
 * or not the date is on the current day. Returns a value like `"in N hours"` or
 * `"in N days"`
 * @param {DateTime} localDateTime DateTime target
 * @returns
 */
export function dateToRelative(localDateTime, isRepeatingEvent) {
  const today = currentTimeUTC().setZone(localDateTime.zoneName);
  const assertion = assertDate(localDateTime, localDateTime.zoneName);

  // "Begins on X" if event is not repeating, or begins after today
  if (assertion.isAfter(today, today.zoneName, "hour") || !isRepeatingEvent) {
    return localDateTime.toFormat("LLL d");
  }

  const eventIsToday = assertion.isSame(today, today.zoneName, "weekday");
  const target = eventIsToday
    ? localDateTime.set({
        day: today.get("day"),
        hours: today.get("hours"),
        month: today.get("month")
      })
    : localDateTime;
  const unit = eventIsToday ? "hours" : "days";
  return target.toRelative({ unit, round: eventIsToday });
}

/**
 * Returns a week start- and end-date, given a reference `dateTime`
 * @param {DateTime} dateTime Date context for week range
 */
export function weekDateRange(dateTime) {
  // Get number of days to last Sunday. Since 1 = Monday (Luxon),
  // offset diff by 1 to get preceding Sunday
  const diff = Math.max(dateTime.weekday - 1, 0);
  const start = dateTime.minus({ days: diff + 1 });
  const end = start.plus({ days: 6 });
  // Return start (Sunday) -  end (Saturday)
  return { start, end };
}

/**
 * Create a Luxon `DateTime` object
 * @param {string|DateTime} date1 Date for comparison basis
 * @param {string} zone Date zone (optional: defaults to local)
 */
export function dateTimeFromISO(date, zone = "utc") {
  const opts = { zone };
  return DateTime.fromISO(date, opts);
}

/**
 * Create a Luxon `DateTime` object
 * @param {string|DateTime} date1 Date for comparison basis
 * @param {string} zone Date zone (optional: defaults to local)
 */
export function dateTimeFromJS(date) {
  return DateTime.fromJSDate(date);
}

/**
 * Prep a date to be persisted to the server as UTC
 * @param {string} date Date for conversion
 * @param {string} zone Origin time-zone for date
 * @returns {string} date time
 */
export function dateTimeForServer(date, zone) {
  const target = DateTime.isDateTime(date)
    ? date.setZone(zone, { keepLocalTime: true })
    : dateTimeFromISO(date, zone);

  return target
    .set({ second: 0, millisecond: 0 })
    .toUTC()
    .toFormat(utcDateTimeFormat);
}

/**
 * Create a Luxon `DateTime` object in UTC
 * @param {string|DateTime} date1 Date for comparison basis
 * @param {string} zone Date zone (optional: defaults to local)
 */
export function utcFromISO(date, zone = "utc") {
  return DateTime.isDateTime(date)
    ? DateTime.fromMillis(date.toMillis())
    : dateTimeFromISO(date, zone).toUTC();
}

/**
 * Get system time zone
 */
export function localTZ() {
  return DateTime.local().zoneName;
}

/**
 * Shift supplied `DateTime` to the nearest `weekday`, `month`, or `year`
 * @param {DateTime} dateTime Target date to shift
 * @param {object} toDateOpts Options for date shift
 * @param {boolean|null} toDateOpts.day Shift to specified `day`
 * @param {boolean|null} toDateOpts.weekday Shift to nearest `weekday`
 * @param {boolean|null} toDateOpts.month Shift to nearest `month`
 * @param {boolean|null} toDateOpts.year Shift to nearest `year`
 *
 * @returns {DateTime} shifted date
 */
export function shiftDate(dateTime, toDateOpts) {
  let unit = Object.keys(toDateOpts).filter(k => toDateOpts[k]);
  const isFutureDate = assertNow().isSameOrBefore(dateTime);

  if (unit.length > 1) {
    throw new Error("shiftDate requires exactly one unit as an option.");
  } else if (["day", "weekday"].includes(unit) && isFutureDate) {
    return dateTime;
  } else unit = unit[0];

  const today = currentTimeUTC().set({
    hour: dateTime.get("hour"),
    minute: dateTime.get("minute"),
    second: 0,
    millisecond: 0
  });
  const shifted = ["month", "year"].includes(unit)
    ? dateTime.set({ [unit]: today.get("unit") })
    : today.set({ [unit]: dateTime.get("unit") });

  const adjustShifted = assertNow().isSameOrAfter(shifted, shifted.zoneName);

  switch (unit) {
    case "day":
      return shifted;

    case "weekday":
      return adjustShifted
        ? shifted.set({ day: shifted.get("day") + 7 })
        : shifted;

    case "month":
      return adjustShifted
        ? shifted.set({ month: today.get("month") + 1 })
        : shifted;

    case "year":
      return adjustShifted
        ? shifted.set({ year: today.get("year") + 1 })
        : shifted;

    default:
      return dateTime;
  }
}

export function isDateTime(d) {
  return DateTime.isDateTime(d);
}
