/* eslint-disable no-undef */
import session from "@/session";
import state from "@/state";
import {
  isAdmin,
  isAssigned,
  isStudent,
  isSuperAdmin,
  isTeacher,
  permissionsFor,
  routeName
} from "@/models/permissions";
import { utcFromISO } from "../../helpers/date-time.helpers";
import { roles, routesFor, SCHEDULER_ADMIN } from "../../models/permissions";

/**
 * Components with permission and acces-control concerns, or components
 * that need access to the signed-in user.
 */
const PermissionsMixin = {
  data: () => ({
    activeUser: null,
    error: null,
    loading: false,
    loadingMessage: null,
    permissions: [],
    unsubscribe: null
  }),

  computed: {
    accessibleRoutes() {
      if (this.activeUser && !this.hasConsented) return [];
      const { role } = this.activeUser || {};
      return routesFor(role);
    },
    appOrgName() {
      return APP_ORG;
    },
    appUrl() {
      return APP_URL;
    },
    hasConsented() {
      const user = this.activeUser;
      return user ? utcFromISO(user.waiverConsentDate).isValid : false;
    },
    isAdminUser() {
      return isAdmin(this.activeUser || {});
    },
    isScheduleAdminUser() {
      const { role } = this.activeUser || {};
      return role === SCHEDULER_ADMIN;
    },
    isStudentUser() {
      return isStudent(this.activeUser || {});
    },
    isSubscriber() {
      return (this.activeUser || {}).isSubscriber;
    },
    isSuperAdminUser() {
      return isSuperAdmin(this.activeUser || {});
    },
    isTeacherUser() {
      return isTeacher(this.activeUser || {});
    },
    requireConsent() {
      const { activeUser, $route } = this;
      const settings = $route.name === "MySettings";

      return activeUser && !this.hasConsented && !settings;
    },
    role() {
      const user = this.activeUser;
      return user ? user.role : null;
    },
    roles() {
      const rank = roles.findIndex(role => role === this.role);
      const r = rank === 0 ? [...roles] : roles.slice(rank, roles.length);
      return r.filter(role => !isSuperAdmin({ role }));
    },
    subRouteName() {
      return routeName(this.$route.name) || "Home";
    },
    trialMembershipActive() {
      return Boolean(this.activeUser) && this.activeUser.trialMembershipActive;
    },
    trialMembershipEligible() {
      return (
        Boolean(this.activeUser) && this.activeUser.trialMembershipEligible
      );
    },
    trialMembershipRelativeEnd() {
      return (
        Boolean(this.activeUser) && this.activeUser.trialMembershipRelativeEnd
      );
    }
  },

  mounted() {
    this.activeUser = state.getState().user;
    this.permissions = permissionsFor(this.role);
  },

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  },

  methods: {
    attachToState() {
      if (this.unsubscribe) return;
      this.unsubscribe = state.subscribe(this.onAppState);
    },

    isAssigned(event) {
      return isAssigned(this.activeUser || {}, event);
    },

    async logout() {
      return await session.logout();
    },

    onAppState({ user }) {
      this.activeUser = user;
    },

    onViewError(error) {
      this.stopLoading();
      this.error = error || null;
    },

    startLoading(msg = null) {
      this.loading = true;
      this.loadingMessage = msg;
    },

    stopLoading() {
      this.loading = false;
      this.loadingMessage = null;
    }
  }
};

export default PermissionsMixin;
