// Role Names
export const ADMIN = "Admin";
export const SUPER_ADMIN = "Super Admin";
export const SCHEDULER_ADMIN = "Scheduler Admin";
export const PRACTITIONER = "Practitioner";
export const TEACHER = "Teacher";
export const STUDENT = "Student";
const ADMINS = [SUPER_ADMIN, SCHEDULER_ADMIN, ADMIN];
const TEACHERS = [PRACTITIONER, TEACHER];
export const roles = [...ADMINS, ...TEACHERS, STUDENT];

// Public Routes
const publicRoutes = {
  Home: "Home",
  Login: "Login",
  ListClasses: "All Classes",
  ListEvents: "All Events",
  NotFound: "Not Found",
  RegisterUser: "Register User",
  ResetPassword: "Reset Password"
};
// Student Routes
const studentRoutes = {
  ...publicRoutes,
  Classes: "My Classes",
  ClassComplete: "Complete",
  JoinClass: "Join Class",
  ViewClass: "Details",
  Events: "My Events",
  EventComplete: "Complete",
  JoinEvent: "Join Event",
  ViewEvent: "Details",
  OneOnOne: "Home",
  CreateOneOnOne: "Create New",
  JoinOneOnOne: "Join Session",
  ListOneOnOne: "My Requests",
  ViewOneOnOne: "Confirm Appt",
  Library: "Video Library",
  MySettings: "My Account"
};
// Teacher Routes
const teacherRoutes = {
  ...studentRoutes,
  ListClassAttendees: "View Attendees",
  ListEventAttendees: "View Attendees"
};
// Admin-Level Routes (shared by admin types)
const adminLevelRoutes = {
  ...teacherRoutes,
  CreateClass: "New Class",
  EditClass: "Edit Class",
  CreateEvent: "New Event",
  EditEvent: "Edit Event",
  EventSettings: "Event Settings",
  CreateUser: "New User",
  EditUser: "Edit User",
  ListUsers: "All Users",
  Users: "Admin",
  EditOneOnOne: "Manage Request"
};
// Admin Routes
const adminRoutes = {
  ...adminLevelRoutes,
  ClassSettings: "Class Settings"
};
// Scheduler Admin Routes
const schedulerAdminRoutes = {
  ...adminLevelRoutes,
  ApproveOneOnOne: "Pending"
};
// Super Admin (everything except "Approve 1:1")
const superAdminRoutes = {
  ...adminRoutes,
  ZoomUserSettings: "Zoom Users"
};

/* Role Permissions */

// Permission Actions
const ACTIONS = model => ({
  CREATE: `Create ${model}`,
  EDIT: `Edit ${model}`,
  JOIN: `Join ${model}`,
  VIEW: `View ${model}`
});
// Permissioned (data) Objects
export const PERMISSIONS = {
  EVENT: ACTIONS("Event"),
  ONE_ON_ONE: ACTIONS("One on One"),
  USER: ACTIONS("User")
};
// User Profile Sections
export const USER_PROFILE_SECTIONS = [
  "Personal Info",
  "Notifications",
  "Account Info",
  "User Bio"
];

/* Helpers */

/**
 * Assert that user has permissions to view current route
 * @param {string} routeName Name of current vue-route (e.g. `Login`)
 * @param {string} role User `role`
 */
export function hasPagePermissions(routeName, role) {
  return routeName ? routesFor(role).includes(routeName) : true;
}

/**
 * Assert user is an `admin` or `super admin`
 * @param {object} data params
 * @param {string} data.role Role to check
 */
export function isAdmin({ role }) {
  return ADMINS.includes(role);
}

/**
 * Assert user is an assigned `Teacher` for the supplied `Event`
 * @param {object} user User to check
 * @param {object} event `Event` to check assignment
 */
export function isAssigned(user, event) {
  const { id } = user || {};
  if (!id) return false;

  const { assignedTeacher: a, backupTeacher: b } = event;
  const isUser = t => t && t.id === id;
  return [a, b].some(isUser);
}

/**
 * Assert user is an `admin`, `super admin`, `teacher`, or `practitioner`
 * @param {object} data params
 * @param {string} data.role Role to check
 */
export function isAdminOrTeacher({ role }) {
  return [...ADMINS, ...TEACHERS].includes(role);
}

/**
 * Assert user is a `Teacher`
 * @param {object} data params
 * @param {string} data.role Role to check
 */
export function isTeacher({ role }) {
  return TEACHERS.includes(role);
}

/**
 * Assert user is a `Teacher`, `Practitioner`, or `Student`
 * @param {object} data params
 * @param {string} data.role Role to check
 */
export function isTeacherOrStudent({ role }) {
  return [...TEACHERS, STUDENT].includes(role);
}

/**
 * Assert user is a `Student`
 * @param {object} data params
 * @param {string} data.role Role to check
 */
export function isStudent({ role }) {
  return role === STUDENT;
}

/**
 * Assert user is a `Scheduler Admin`
 * @param {object} data params
 * @param {string} data.role Role to check
 */
export function isSchedulerAdmin({ role }) {
  return role === SCHEDULER_ADMIN;
}

/**
 * Assert user is a `Super Admin`
 * @param {object} data params
 * @param {string} data.role Role to check
 */
export function isSuperAdmin({ role }) {
  return role === SUPER_ADMIN;
}

/**
 * Get a list of permissions for the supplied User `role`
 * @param {string} role Role to check
 */
export function permissionsFor(role) {
  const { EVENT, ONE_ON_ONE } = PERMISSIONS;
  const entries = o => Object.keys(o).map(k => o[k]);
  const ALL_OBJECTS = entries(PERMISSIONS);
  const oneOnOnePerms = entries(ONE_ON_ONE);

  switch (true) {
    case isAdmin({ role }):
      return ALL_OBJECTS.reduce(
        (adminPermissions, mvpObject) =>
          Object.keys(mvpObject).reduce((perms, action) => {
            perms.push(mvpObject[action]);
            return perms;
          }, adminPermissions),
        []
      );

    case isTeacher({ role }):
      return [EVENT.VIEW, EVENT.JOIN, ...oneOnOnePerms];

    case isStudent({ role }):
      return [EVENT.VIEW, EVENT.JOIN, ...oneOnOnePerms].filter(
        p => p !== ONE_ON_ONE.EDIT
      );

    default:
      return [EVENT.VIEW];
  }
}

/**
 * List of routes current user with `role` can access.
 * @param {string} role User `role`
 */
export function routesFor(role) {
  switch (true) {
    case isSuperAdmin({ role }):
      return Object.keys(superAdminRoutes);

    case isAdmin({ role }):
      return isSchedulerAdmin({ role })
        ? Object.keys(schedulerAdminRoutes)
        : Object.keys(adminRoutes);

    case isTeacher({ role }):
      return Object.keys(teacherRoutes);

    case isStudent({ role }):
      return Object.keys(studentRoutes);

    default:
      return Object.keys(publicRoutes);
  }
}

/**
 * Get a user-friendly name for the supplied `route`
 * @param {string} route Name of route to check
 */
export function routeName(route) {
  return { ...schedulerAdminRoutes, ...superAdminRoutes }[route] || null;
}
