<template>
  <div class="branded-divider">
    <hr class="divider" />
    <img
      src="@assets/logo-symbol-99x99.png"
      alt="logo"
      width="32"
      height="32"
    />
    <hr class="divider" />
  </div>
</template>

<script>
export default { name: "BrandedDivider" };
</script>

<style lang="scss">
.branded-divider {
  @include flex();
  margin-bottom: $xlg;
  margin-top: $xlg;
  opacity: 0.65;
  width: $parent;

  &.low-margin {
    margin-bottom: $xs;
    margin-top: $xs;
  }

  &.no-margin {
    margin-bottom: 0;
    margin-top: 0;
  }

  hr {
    background-color: $grey-light;
    flex-grow: 1;
  }

  img {
    margin: 0 $sm;
  }
}
</style>
