import apiConfig from "@network";
import MVPEvent from "./event.model";
import { getEventById } from "./events";
import EventRegistration from "./registrations.model";
import { getUserById } from "./user";

/**
 * Get all users that registered for a specific `Class`
 * @param {object} params Request params. Requires `classId`
 * @param {object} params.classId (Optional) Return registrations for this `classId`
 */
export async function getClassRegistrations(params) {
  const [res, event] = await Promise.all([
    apiConfig.events.getClassRegistrations(params),
    getEventById(params.classId)
  ]);
  const registrationUsers = await getRegistrationUsers(res.data);
  return registrationUsers.map(reg => ({ ...EventRegistration(reg), event }));
}

/**
 * Get all users that registered for a specific `Event`
 * @param {object} params event params. Requires `eventId`
 * @param {object} params.eventId (Optional) Return registrations for this `eventId`
 */
export async function getEventRegistrations(params) {
  const [res, event] = await Promise.all([
    apiConfig.events.getEventRegistrations(params),
    getEventById(params.eventId)
  ]);

  const registrationUsers = await getRegistrationUsers(res.data);
  return registrationUsers.map(reg => ({ ...EventRegistration(reg), event }));
}

/**
 * Get and attach user data for each registration
 * @param {object} data Response from `getEventRegistrations` or `getClassRegistrations`
 * @returns {array} list of registrations with attached user data
 */
export async function getRegistrationUsers(data) {
  // get unique users by ID
  const userPromises = data.map(({ userId }) => getUserById(userId));
  const users = await Promise.all(userPromises);

  return data.map(({ userId, ...rest }) => ({
    ...rest,
    userId,
    user: users.find(({ id }) => id === userId)
  }));
}

/**
 * Register active user for a `class` or `event`
 * @param {object} params event params. Requires (either `eventId` or
 * `classId`) AND a `userId` to make request
 * @param {object} params.classId (Optional) Class ID
 * @param {object} params.eventId (Optional) Event ID
 * @param {object} params.userId (Optional) Event ID
 */
export async function registerAttendee(params) {
  // validate
  if (!params.classId && !params.eventId) {
    throw new Error("Request requires either Class Id or Event id");
  }
  const { data } = await apiConfig.events.registerAttendee(params);
  return data;
}

async function getAssignedClasses(teacherId) {
  const { data } = await apiConfig.events.getAssignedClasses({ teacherId });
  return data.map(MVPEvent);
}

async function getAssignedEvents(teacherId) {
  const { data } = await apiConfig.events.getAssignedEvents({ teacherId });
  return data.map(MVPEvent);
}

/**
 * Get all `events` and `classes` that a user registered to attend.
 * @param {object} params event params. Requires (either `eventId` or
 * `classId`) AND a `userId` to make request
 * @param {object} params.userId (Optional) Return registrations for this `User ID`
 * @param {object} params.eventId (Optional) Return Event with id `Event ID`
 * @param {object} params.classId (Optional) Return Online Class with id `Class ID`
 * @param {object} params.teacherId (Optional) Get Classes and Events to which `teacherId` is assigned
 */
export async function getUserRegistrations(params) {
  // User's (purchased/free) classes and events
  const promises = [apiConfig.events.getUserRegistrations(params)];
  // User's assigned classes and events
  if (params.teacherId) {
    promises.push(
      getAssignedClasses(params.teacherId),
      getAssignedEvents(params.teacherId)
    );
  } else promises.push(Promise.resolve([]), Promise.resolve([]));
  const [userRegs, classes, events] = await Promise.all(promises);
  const regEvents = await Promise.all(
    userRegs.data.map(({ eventId, classId }) =>
      getEventById(eventId || classId)
    )
  );
  const aggregate = {
    events: [],
    onlineClasses: [],
    assignedClasses: classes,
    assignedEvents: events
  };

  return userRegs.data.reduce((agg, reg, i) => {
    const registration = EventRegistration(reg);
    registration.event = regEvents[i];
    // Separate classes from events
    if (reg.classId) {
      agg.onlineClasses.push(registration);
    } else agg.events.push(registration);
    // return aggregate
    return agg;
  }, aggregate);
}

/**
 * Canel a User's registration to an `event` or `class`
 * @param {object} registrationId Id of `Registration` to be deleted
 * @returns {boolean}
 */
export async function unregisterFromEvent(registrationId, params) {
  await apiConfig.events.unregisterFromEvent({ registrationId });
  return getUserRegistrations(params);
}

/**
 * Assert User is registerd to an `event` or `class` (given either key in `params`)
 * @param {object} params event params. Requires (either `eventId` or
 * `classId`) AND a `userId` to make request
 * @param {object} params.userId (Required) Return registrations for this `User ID`
 * @param {object} params.eventId (Optional) Return Event with id `Event ID`
 * @param {object} params.classId (Optional) Return Online Class with id `Class ID`
 * @returns {boolean}
 */
export async function userRegistration(params) {
  let checkRegistration;

  if (params.classId) {
    checkRegistration = apiConfig.events.checkClassRegistration;
  } else if (params.eventId) {
    checkRegistration = apiConfig.events.checkEventRegistration;
  } else return null;

  const { data } = await checkRegistration(params);

  return data[0] || null;
}
