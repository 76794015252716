/* eslint-disable no-undef */

import { METHODS } from "../api.config";

/** Endpoints for `Events` and `Classes` */
export default {
  /* Super-Admin | Create Online Class Titles */
  createOnlineClassTitle: {
    group: "eventsAdmin",
    url: () => `${API_URL}/class-titles`,
    method: METHODS.POST
  },
  /* Super Admin | Update an Online Class Title */
  updateOnlineClassTitles: {
    group: "eventsAdmin",
    url: ({ classTitleId }) => `${API_URL}/class-titles/${classTitleId}`,
    method: METHODS.PATCH
  },
  /* Admins | Fetch Online Class Titles */
  getOnlineClassTitles: {
    group: "events",
    url: () => `${API_URL}/class-titles`
  },
  /* Events and Classes */
  createEvent: {
    group: "events",
    url: () => `${API_URL}/events`,
    method: METHODS.POST
  },
  deleteEvent: {
    group: "events",
    url: ({ eventId }) => `${API_URL}/events/${eventId}`,
    method: METHODS.DELETE
  },
  getAssignedEvents: {
    group: "events",
    url: ({ teacherId }) => `${API_URL}/events/assigned/${teacherId}`
  },
  getAssignedClasses: {
    group: "events",
    url: ({ teacherId }) => `${API_URL}/events/online/assigned/${teacherId}`
  },
  getAllPublicEvents: {
    group: "events",
    url: () => `${API_URL}/events/public`
  },
  getAllPublicClasses: {
    group: "events",
    url: () => `${API_URL}/events/public/online`
  },
  getEventById: {
    group: "events",
    url: ({ eventId }) => `${API_URL}/events/public/${eventId}`
  },
  updateEvent: {
    group: "events",
    url: ({ id }) => `${API_URL}/events/${id}`,
    method: METHODS.PATCH
  },
  /* Event/Class Registrations */
  registerAttendee: {
    group: "events",
    url: () => `${API_URL}/registrations`,
    method: METHODS.POST
  },
  getClassRegistrations: {
    group: "events",
    url: ({ classId }) => `${API_URL}/registrations?classId=${classId}`
  },
  getEventRegistrations: {
    group: "events",
    url: ({ eventId }) => `${API_URL}/registrations?eventId=${eventId}`
  },
  getUserRegistrations: {
    group: "events",
    url: ({ userId }) => `${API_URL}/registrations?userId=${userId}`
  },
  checkClassRegistration: {
    group: "events",
    url: ({ userId, classId }) =>
      `${API_URL}/registrations/check?userId=${userId}&classId=${classId}`
  },
  checkEventRegistration: {
    group: "events",
    url: ({ userId, eventId }) =>
      `${API_URL}/registrations/check?userId=${userId}&eventId=${eventId}`
  },
  unregisterFromEvent: {
    group: "events",
    url: ({ registrationId }) => `${API_URL}/registrations/${registrationId}`,
    method: METHODS.DELETE
  }
};
