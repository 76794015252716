/* eslint-disable no-undef */

import { METHODS } from "../api.config";

/** `User` Endpoints */
export default {
  createUser: {
    group: "users",
    url: () => `${API_URL}/users`,
    method: METHODS.POST
  },
  deleteUser: {
    group: "users",
    url: ({ userId }) => `${API_URL}/users/${userId}`,
    method: METHODS.DELETE
  },
  getAllUsers: {
    group: "users",
    url: () => `${API_URL}/users/`
  },
  getUsersByRole: {
    group: "users",
    url: ({ role }) => `${API_URL}/users?role=${role}`
  },
  getUserById: {
    group: "users",
    url: ({ userId }) => `${API_URL}/users/${userId}`
  },
  registerUser: {
    group: "users",
    url: () => `${API_URL}/registration`,
    method: METHODS.POST
  },
  updateUser: {
    group: "users",
    url: ({ id }) => `${API_URL}/users/${id}`,
    method: METHODS.PATCH
  },
  searchUsers: {
    group: "users",
    url: ({ queryString }) => `${API_URL}/users/search?query=${queryString}`,
    method: METHODS.GET
  }
};
