<template>
  <custom-legend v-if="activeUser" :items="eventsListLegend" />
</template>

<script>
import CustomLegend from "./CustomLegend.vue";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "EventsListLegend",

  components: { CustomLegend },

  mixins: [PermissionsMixin],

  props: { items: { type: Array, required: true } },

  computed: {
    eventsListLegend() {
      return [
        {
          icon: `fas fa-bookmark error--text`,
          text: `Primary Teacher`,
          condition: this.items.includes(`Primary Teacher`)
        },
        {
          icon: `far fa-bookmark error--text`,
          text: `Substitute`,
          condition: this.items.includes(`Substitute`)
        },
        {
          icon: `far fa-calendar success--text`,
          text: `Weekly`,
          condition: this.items.includes(`Weekly`)
        }
      ].filter(({ condition }) => condition);
    }
  },

  mounted() {
    this.attachToState();
    this.stopLoading();
  }
};
</script>
