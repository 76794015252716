import { currentTimeUTC, dateTimeFromISO } from "../helpers/date-time.helpers";

/**
 * Returns an object representing an app user.
 * @param {object} data Data to hydrate the `User` instance
 * @param {string|undefined} country user country
 * @param {string|undefined} description user description
 * @param {string|undefined} email user email
 * @param {boolean|undefined} emailOptIn user opted in to email notifications
 * @param {date|undefined} emailVerifiedAt when user verified email
 * @param {boolean|undefined} employee whether user is MVP employee
 * @param {string|undefined} firstName user firstName
 * @param {string|undefined} fullName user fullName
 * @param {number|undefined} id user id
 * @param {string|undefined} lastName user lastName
 * @param {boolean|undefined} mobileOptIn user opted in to mobile notifications
 * @param {string|undefined} proNouns user choice pronouns (he/she/they/etc)
 * @param {string|undefined} role user role (`Super Admin`, `Admin`, `Teacher`, `Student`)
 * @param {string|undefined} timeZone user timezone (e.g. `America/New_York`)
 * @param {string|undefined} username user username
 * @param {string|undefined} stripeAcctId user's stripe acct id
 * @param {boolean|undefined} stripeSubActive user has active stripe subscription
 * @param {string|undefined} zoomUserId user's MyVinyasaPractice zoom account id
 */
export default function User(data = {}) {
  const { firstName = "", lastName = "", trialEndDate, stripeSubActive } = data;
  const user = {
    firstName: firstName || null,
    lastName: lastName || null,
    country: data.country || null,
    description: data.description || null,
    email: data.email || null,
    employee: data.employee || false,
    emailOptIn: data.emailOptIn || null,
    emailVerifiedAt: data.emailVerifiedAt || null,
    mobileOptIn: data.mobileOptIn || null,
    proNouns: data.proNouns || null,
    role: data.role || null,
    timeZone: data.timeZone || null,
    username: data.username || null,
    userImage: data.userImage || null,
    // Stripe
    stripeAcctId: data.stripeAcctId || null,
    stripeSubActive: stripeSubActive || false,
    subscriptionEnd: data.subscriptionEnd || null,
    // Membership Trial
    trialEndDate: trialEndDate || null,
    // Zoom
    zoomUserId: data.zoomUserId || null,

    // Computed
    get fullName() {
      return `${firstName || ""} ${lastName || ""}`.trim();
    },

    get isSubscriber() {
      return stripeSubActive || false;
    },

    get trialMembershipActive() {
      return trialEndDate
        ? dateTimeFromISO(trialEndDate) > currentTimeUTC()
        : false;
    },

    get trialMembershipEligible() {
      return !trialEndDate && !stripeSubActive;
    },

    get trialMembershipRelativeEnd() {
      return trialEndDate ? dateTimeFromISO(trialEndDate).toRelative() : null;
    }
  };

  // Attach optional fields
  const legal = [
    "id",
    "cardAgreement",
    "membershipAgreement",
    "waiverConsentDate"
  ];

  legal.forEach(field => {
    if (data[field]) user[field] = data[field];
  });

  // Password
  if (data.password && data.password === data.confirmPassword) {
    user.password = data.password;
  }

  return user;
}
