<template>
  <section id="terms-of-service" class="route">
    <loader v-if="loading" :loadingMessage="`Submitting ...`" />

    <h1 class="title">
      Welcome to
      <span class="accent--text">{{ appOrgName }}</span>
    </h1>

    <div class="route-content column content--centered">
      <p class="description">
        Please take a moment to review the agreements below. Use the provided
        controls to express your consent.
      </p>

      <form id="terms-of-service-form" class="flex--column" @submit.prevent>
        <fieldset>
          <legend>
            <i class="fas fa-file-contract accent--text"></i>
            Terms of Service
          </legend>

          <label
            class="flex"
            for="termsAndConditions"
            @click.stop="
              appendAndEmit({ termsAndConditions: !form.termsAndConditions })
            "
          >
            <input
              type="checkbox"
              name="termsAndConditions"
              v-model="form.termsAndConditions"
            />

            <span class="checkbox-label">
              By checking this box, I acknowledge that I have read and agree to
              the terms and conditions provided by
              <strong>My Vinyasa Practice</strong>&nbsp;
              <a
                href="https://www.myvinyasapractice.com/terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
                >here</a
              >
            </span>
          </label>

          <label
            class="flex"
            for="electronicWaiver"
            @click.stop="
              appendAndEmit({ electronicWaiver: !form.electronicWaiver })
            "
          >
            <input
              type="checkbox"
              name="electronicWaiver"
              v-model="form.electronicWaiver"
            />

            <span class="checkbox-label">
              By checking this box, I am electronically signing this
              <a
                :href="`${appUrl}/vendor/legal/mvp-waiver.pdf`"
                target="_blank"
                rel="noopener noreferrer"
                >waiver</a
              >
              in agreement with <strong>My Vinyasa Practice</strong>.
            </span>
          </label>
        </fieldset>

        <div class="form-controls">
          <button class="outline button--exit" @click.prevent="logout">
            <i class="fas fa-times-circle"></i>
            decline (Exit)
          </button>
          <button
            class="success button--confirm"
            :disabled="!formIsValid"
            @click.prevent="expressConsent"
          >
            <i class="fas fa-check-circle"></i>
            consent (continue)
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Loader from "../components/Loader.vue";
import FormsMixin from "../components/mixins/forms.mixin";
import PermissionsMixin from "../components/mixins/permissions.mixin";
import User from "../models/user.model";
import { updateUser } from "../models/user";
import state from "../state";

export default {
  name: "LegalAgreements",

  components: { Loader },

  mixins: [PermissionsMixin, FormsMixin],

  computed: {
    requiredFields() {
      return ["termsAndConditions", "electronicWaiver"];
    }
  },

  methods: {
    async expressConsent() {
      if (!this.formIsValid) return;

      this.loading = true;
      try {
        const usr = User({ ...this.activeUser, waiverConsentDate: new Date() });
        const updated = await updateUser(usr);
        state.user(updated);
      } catch (error) {
        this.onFormError(error.message || error);
      }
    }
  }
};
</script>

<style lang="scss">
#terms-of-service {
  form {
    margin: 0 auto;
  }

  label {
    margin-bottom: $xxs;
  }

  .button--confirm,
  .button--exit {
    box-shadow: none;
    max-width: $form-width;
    width: $half;
  }

  .button--exit {
    margin-right: $xs;
    color: $error;

    &:hover {
      background-color: $error;
      color: white;
    }
  }

  .form-controls {
    width: $parent;
  }
}

@media screen and (max-width: 768px) {
  #terms-of-service {
    .form-controls {
      flex-direction: column;

      .button--confirm,
      .button--exit {
        margin: 0;
        width: $parent;
      }

      .button--exit {
        margin-top: $xs;
        order: 2;
      }
    }
  }
}
</style>
