<template>
  <header id="app-header">
    <router-link to="/">
      <img
        id="logo"
        alt="My Vinyasa Practice logo"
        src="@assets/myp-logo.png"
        height="36"
        width="243"
      />
    </router-link>

    <hr class="divider divider--vertical grow" />

    <nav
      id="nav"
      :class="`floating-menu slide-${showMenu ? 'in' : 'out'}-right`"
    >
      <!-- Mobile Menu | Show -->
      <a class="exit-bar mobile-menu" role="button" @click.prevent="toggleMenu">
        <i class="fas fa-times" />
      </a>

      <!-- External -->
      <a
        v-for="(href, text) in externalLinks"
        :key="text"
        class="link"
        :href="href"
        target="_blank"
      >
        {{ text }}
      </a>

      <!-- App Nav links -->
      <router-link
        v-for="(link, i) in links"
        :key="link.text"
        :class="linkClass(link.to.name)"
        :style="`animation-delay: ${i * 120}ms`"
        :to="link.to"
      >
        <i v-if="link.to.name === 'MySettings'" :class="userLinkIcon"></i>&nbsp;
        {{ link.text }}
      </router-link>
    </nav>

    <!-- Mobile Menu | Hide -->
    <a class="mobile-menu" role="button" @click.prevent="toggleMenu">
      <i class="fas fa-bars primary-dark--text mobile-menu" />
    </a>

    <!-- "New Content Available" banner -->
    <aside v-if="newContentAvailable" class="new-content">
      <h1 class="h5 success-dark--text justify">App Updated!</h1>
      <p class="list-item list-item--label">
        New content is available. Please
        {{ isNative ? "reload your window." : "relaunch the app." }}
      </p>
    </aside>

    <!-- "Install PWA" prompt -->
    <aside v-if="pwaPrompt" class="pwa-prompt slide-down-fade-in">
      <h1 class="h6 success--text">
        Save the <b class="accent--text">{{ appOrgName }}</b> app to your
        homescreen?
      </h1>

      <div class="list-item list-item--label">
        <button class="button success" @click.prevent="install">Install</button>
        <button class="outline error" @click.prevent="dismiss">Cancel</button>
      </div>
    </aside>
  </header>
</template>

<script>
import { Capacitor } from "@capacitor/core";
/* eslint-disable no-undef */
import state from "../state";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "AppHeader",

  mixins: [PermissionsMixin],

  data: () => ({
    newContentAvailable: false,
    openMenu: false,
    pwaPrompt: null,
    swRegistrationEvent: null,
    unsubscribe: null
  }),

  computed: {
    externalLinks() {
      /* Keys are text, values are href */
      return NODE_ENV === "development"
        ? {}
        : {
            Training: "https://www.myvinyasapractice.com/trainings/",
            Services: "https://www.myvinyasapractice.com/services/",
            "Student Login": "https://myvinyasapractice.teachable.com/courses",
            About:
              "https://www.myvinyasapractice.com/about-online-yoga-teacher-training/",
            Shop: "https://www.myvinyasapractice.com/shop/"
          };
    },

    isNative() {
      return Capacitor.isNative;
    },

    links() {
      const { activeUser, accessibleRoutes } = this;
      // HELPER | Create a link that requires a logged-in user
      const authLink = (text, name) => ({
        text,
        to: { name },
        condition: Boolean(activeUser)
      });

      // Return only routes accessible to the current user
      return [
        { text: "Events", to: { name: "ListEvents" }, condition: true },
        { text: "Classes", to: { name: "ListClasses" }, condition: true },
        { text: "MVP Login", to: { name: "Login" }, condition: !activeUser },
        authLink("Library", "Library"),
        authLink("Users", "ListUsers"),
        authLink("One-on-One", this.oneOnOneRoute),
        authLink((activeUser || {}).firstName, "MySettings")
      ].filter(l => l.condition && accessibleRoutes.includes(l.to.name));
    },

    mobileMenu() {
      return window.innerWidth <= 1200;
    },

    oneOnOneRoute() {
      return this.isScheduleAdminUser ? "ApproveOneOnOne" : "ListOneOnOne";
    },

    showMenu() {
      return this.openMenu;
    },

    userLinkIcon() {
      const { trialMembershipActive, activeUser } = this;
      return {
        "fas fa-key": true,
        "success--text": (activeUser || {}).stripeSubActive,
        "accent--text": trialMembershipActive
      };
    }
  },

  mounted() {
    this.resetMenu();
    this.attachToState();
    window.addEventListener("resize", this.resetMenu, false);

    // PWA install prompt
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      this.pwaPrompt = e;
    });

    window.addEventListener("appinstalled", () => (this.pwaPrompt = null));
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resetMenu, false);
  },

  methods: {
    dismiss() {
      this.pwaPrompt = null;
    },

    async install() {
      await this.pwaPrompt.prompt();
    },

    reloadApp() {
      state.newContentAvailable(false);
      const { swRegistrationEvent } = this;
      if (!swRegistrationEvent || !swRegistrationEvent.waiting) {
        return window.location.reload();
      } else swRegistrationEvent.waiting.postMessage({ type: "SKIP_WAITING" });
    },

    resetMenu() {
      return (this.openMenu = window.innerWidth > 1200);
    },

    linkClass(routeName) {
      const { name } = this.$route;
      const listOrApprove = new RegExp(`^(List|Approve)`);
      /* Initially match the route name for all others */
      let active = new RegExp(routeName).test(name);
      /* If route begins with "List" or "Approve" run some additional logic */
      if (listOrApprove.test(routeName)) {
        // Get second half of string (e.g "Events" in "ListEvents") and drop last 's'
        const nameParts = routeName.split(listOrApprove);
        let entity = nameParts[nameParts.length - 1];
        const end = entity.length - 1;
        if (entity.charAt(end) === "s") {
          entity = entity === "Classes" ? "Class" : entity.substr(0, end);
        }

        active = new RegExp(entity).test(name);
      }

      return { link: true, active, "slide-in-right": true };
    },

    onAppState({ user, newContentAvailable, serviceWorkerRegistration }) {
      this.activeUser = user;
      this.newContentAvailable = newContentAvailable;

      if (serviceWorkerRegistration) {
        this.showAppUpdated(serviceWorkerRegistration.detail);
      }
    },

    showAppUpdated(swRegistrationEvent) {
      this.swRegistrationEvent = swRegistrationEvent;
      return state.newContentAvailable(true);
    },

    toggleMenu(e) {
      e.stopPropagation();
      this.openMenu = !this.openMenu;

      const $body = document.body;
      if (this.openMenu) {
        $body.addEventListener("click", this.toggleMenu, false);
      } else {
        $body.removeEventListener("click", this.toggleMenu, false);
      }
    }
  }
};
</script>

<style src="./AppHeader.scss" lang="scss"></style>
