import { METHODS } from "./api.config";
import eventsEndpoints from "./endpoint-sections/events.endpoints";
import oneOnOneEndpoints from "./endpoint-sections/one-on-one.endpoints";
import stripeEndpoints from "./endpoint-sections/stripe.endpoints";
import usersEndpoints from "./endpoint-sections/users.endpoints";
import youtubeEndpoints from "./endpoint-sections/youtube.endpoints";
import zoomEndpoints from "./endpoint-sections/zoom.endpoints";

/* eslint-disable no-undef */
export default {
  /* Auth */
  login: {
    url: () => `${API_URL}/auth`,
    method: METHODS.POST
  },
  logout: {
    url: () => `${API_URL}/auth/logout`,
    method: METHODS.POST
  },
  refreshToken: {
    url: () => `${API_URL}/auth/refresh`,
    method: METHODS.POST
  },
  validateResetToken: {
    url: ({ email, token }) =>
      `${API_URL}/passwords/reset?email=${email}&token=${token}`
  },
  forgotPassword: {
    url: () => `${API_URL}/passwords/forgot`,
    method: METHODS.POST
  },
  resetPassword: {
    url: ({ email, token }) =>
      `${API_URL}/passwords/reset?email=${email}&token=${token}`,
    method: METHODS.POST
  },
  uploadFile: {
    contentType: "multipart/form-data",
    redirect: "follow",
    url: () => `${API_URL}/upload`,
    method: METHODS.POST
  },

  /* Library/YouTube Channel */
  ...youtubeEndpoints,

  /* Zoom */
  ...zoomEndpoints,

  /* Users */
  ...usersEndpoints,

  /* Events and Classes */
  ...eventsEndpoints,

  /* One-on-One */
  ...oneOnOneEndpoints,

  /* Stripe */
  ...stripeEndpoints
};
