import {
  getUserRegistrations,
  registerAttendee,
  unregisterFromEvent
} from "@/models/registrations";
import state from "@/state/";
import EventsMixin from "./events.mixin";

/**
 * Shared behavior for user registration to `Events` and `Online Classes`
 */
const RegistrationsMixin = {
  name: "RegistrationsMixin",

  mixins: [EventsMixin],

  data: () => ({
    loading: true,
    activeNotification: null,
    assignedClasses: [],
    assignedEvents: [],
    classRegistrations: [],
    eventRegistrations: [],
    eventNotification: null,
    unsubscribe: null,
    willPay: false
  }),

  computed: {
    canRegister() {
      if (this.event.hasEnded) return false;

      const conditions = ["inProgress", "assigned", "isRegistered"];
      return (
        this.event.registrationStatus === "Open" &&
        this.event.eventStatus === "Open" &&
        conditions.every(condition => !this[condition])
      );
    },

    canBeJoined() {
      return this.event.canBeJoined;
    },

    isRegistered() {
      return Boolean(this.registration);
    },

    noRegistrations() {
      const regs = [
        this.assignedClasses,
        this.assignedEvents,
        this.classRegistrations,
        this.eventRegistrations
      ];
      return regs.every(list => list.length === 0);
    },

    registration() {
      const regs = this.registrationSrc;
      const key = this.idKey;
      const id = this.eventParams[key];
      return regs.length === 0 ? null : regs.find(r => r[key] === id);
    },

    registrationSrc() {
      return this.isOnlineClass
        ? this.classRegistrations
        : this.eventRegistrations;
    }
  },

  async mounted() {
    this.attachToState();
    this.onAppState(state.getState());
    // Check if user is registered for current event
    this.assertRegistration();
    this.loading = false;
  },

  methods: {
    assertRegistration() {
      // Show notification if user is registered or event is in progress
      if (this.isRegistered) {
        this.notifyRegistration();
      } else if (this.inProgress) {
        this.notifyRegistration(`${this.type} in progress!`);
      }
    },

    onStartAttempt() {
      // override in implementation
    },

    onJoinAttempt() {
      // override in implementation
    },

    onAttemptRegister() {
      this.notifyError(null);

      switch (true) {
        // Online classes
        case this.isOnlineClass:
          return this.activeUser.employee ||
            this.activeUser.stripeSubActive ||
            this.trialMembershipActive
            ? this.register()
            : this.showModalForProduct();

        // Paid Events (with a product id)
        case Boolean(this.event.productId):
          this.willPay = true;
          return this.showModalForProduct(this.event.productId);

        // Free events
        default:
          return this.register();
      }
    },

    /**
     * @override `Permissions.Mixin` method for responding to state
     * @param {object} state AppState
     */
    onAppState({ user, userRegistrations = {} }) {
      this.activeUser = user;
      this.onUserRegistrations(userRegistrations || {});
    },

    onUserRegistrations(registrations = {}) {
      this.assignedClasses = registrations.assignedClasses || [];
      this.assignedEvents = registrations.assignedEvents || [];
      this.classRegistrations = registrations.onlineClasses || [];
      this.eventRegistrations = registrations.events || [];
      if (this.registrationSrc.length > 0) this.assertRegistration();
    },

    showModalForProduct(activeProductId = null) {
      // Register user, remove payment handler ref, and close modal
      return state.multiple({
        // Optional product to fetch and show in modal
        activeProductId,
        // Boolean to show/hide modal
        showProductModal: true
      });
    },

    async register(result = {}) {
      // If there is no payment response, there may have been a failure
      if (!result.id && this.willPay) {
        const error =
          "Your payment may not have gone through. " +
          "Confirm that you were not charged, then reload and try again. " +
          "If the issue persists, please contact My Vinyasa Practice support.";
        return this.notifyError(error);
        // Otherwise reset the payment expectation.
      } else if (this.willPay) this.willPay = false;

      this.notifyError(null);
      this.startLoading("Registering ...");

      try {
        // register user
        const params = this.eventParams;
        if (result.id) params.paymentId = result.id;
        await registerAttendee(params);

        // update list of user registrations in app
        const userRegistrations = await getUserRegistrations(params);

        // update application and hide modal
        state.multiple({
          activeProductId: null,
          showProductModal: false,
          userRegistrations
        });

        // show notification
        this.stopLoading();
      } catch (error) {
        this.notifyError(error.message || error);
      }
    },

    async unregister() {
      this.notifyError(null);
      this.startLoading("Canceling registration ...");

      try {
        const registrations = await unregisterFromEvent(
          this.registration.id,
          this.eventParams
        );
        state.userRegistrations(registrations);
        this.stopLoading();
        this.notifyRegistration();
      } catch (error) {
        this.notifyError(error.message || error);
      }
    },

    notifyError(message) {
      this.onViewError(message);
      this.$emit("event-error", message);
    },

    notifyRegistration(msg, useFallback = true) {
      window.clearTimeout(this.activeNotification);
      this.activeNotification = null;
      let fb;

      // generate a fallback message and optionally clear UI notification
      if (useFallback) {
        if (this.isRegistered) {
          fb = `You have registered for this ${this.type.toLowerCase()}.`;
        } else {
          fb = "Your registration has been canceled.";
          // Clear notification message in 3 seconds
          const clear = () => this.notifyRegistration(null, false);
          this.activeNotification = window.setTimeout(clear, 3000);
        }

        this.eventNotification = msg || fb;
      } else this.eventNotification = msg;
      this.$emit("registration-event", event);
    }
  }
};

export default RegistrationsMixin;
