/* eslint-disable no-undef */
import { METHODS } from "../api.config";

/** Library/YouTube Channel Endpoints */
export default {
  createPlaylists: {
    url: () => `${API_URL}/videos/playlists`,
    method: METHODS.POST
  },

  getVideo: {
    url: ({ playlist }) => `${API_URL}/videos?playlist=${playlist}`
  },

  getPlaylistLibrary: {
    url: () => `${API_URL}/videos/playlists`
  },

  getSavedPlaylists: {
    url: ({ subscriber = 0 }) =>
      `${API_URL}/videos/playlists/saved?subscriber=${subscriber}`
  },

  updatePlaylist: {
    url: ({ playlistId }) => `${API_URL}/videos/playlists/${playlistId}`,
    method: METHODS.PATCH
  }
};
