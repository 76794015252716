/* eslint-disable no-undef */

import { METHODS } from "../api.config";

/** Endpoints for `Zoom` */
export default {
  /* Zoom */
  createZoomMeeting: {
    group: "zoom",
    url: ({ zoomUserId }) => `${API_URL}/meetings?userId=${zoomUserId}`,
    method: METHODS.POST
  },
  deleteZoomMeeting: {
    group: "zoom",
    url: ({ meetingId }) => `${API_URL}/meetings?meetingId=${meetingId}`,
    method: METHODS.DELETE
  },
  getMeetingParticipants: {
    group: "zoom",
    url: ({ meetingId: id }) =>
      `${API_URL}/meetings/meetingParticipants?meetingId=${id}`
  },
  getZoomSignature: {
    group: "zoom",
    url: () => `${API_URL}/meetings/signature`,
    method: METHODS.POST
  },
  getZoomUsers: {
    group: "zoom",
    url: ({ userId = "" }) => `${API_URL}/meetings/zoomUsers?userId=${userId}`
  }
};
