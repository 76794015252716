import apiConfig from "@network";
import { TIMEZONES, ZOOM_TIMEZONES } from "../utilities";
import { DateTime } from "luxon";
import ZoomMeeting, { ZoomParticipant, ZoomUser } from "./zoom.model";

/* Data fetch */

/**
 * @param {object} params Request params
 * @param {object} params.role Zoom meeting role (`1` = host, `0` = attendee)
 * @param {object} params.meetingNumber Zoom meeting id
 */
export async function getZoomSignature(params) {
  const { data } = await apiConfig.zoom.getZoomSignature(params);
  return data;
}

export async function getZoomUsers() {
  const { data } = await apiConfig.zoom.getZoomUsers();

  return { ...data, users: data.map(ZoomUser) };
}

export async function getMeetingParticipants(meetingId) {
  const params = { meetingId };
  const {
    participants = { participants: [] }
  } = await apiConfig.zoom.getMeetingParticipants(params);
  return participants.participants.map(ZoomParticipant);
}

/**
 * Schedule a zoom meeting and update a dataset with a
 * zoom start link, join link, and meeting id.
 * @param {object} formData Meeting data/config
 * @param {string} formData.scheduledDate meeting date (local, no timezone)
 * @param {string} formData.name meeting title
 * @param {string} formData.description meeting description
 * @param {string} formData.scheduledTimeZone meeting time-zone
 * @param {number|undefined} formData.meetingLength (optional) meeting length in mins
 * @param {string|undefined} formData.scheduledEndDate (optional) meeting end date
 * @param {string|undefined} formData.meetingPassword (optional) meeting password
 * @param {string|undefined} formData.zoomUserId (required) meeting scheduler's zoom id
 *
 * @returns {{
 * zoomLink: string
 * zoomStartLink: string
 * zoomMeetingId: string
 * occurrences:array\objects
 * zoomMeetingPasscode: string|undefined
 * }} request response details
 *
 */
export async function scheduleMeeting(formData) {
  if (!formData.zoomUserId) {
    throw new Error("`scheduleMeeting` requires a zoom user id!");
  }

  const format = "yyyy-MM-dd'T'HH:mm:ss";
  const scheduledDate = new Date(formData.scheduledDate);
  const scheduledEndDate = new Date(formData.scheduledEndDate);
  const opts = {
    topic: formData.name,
    agenda: formData.description,
    start_time: DateTime.fromJSDate(scheduledDate).toFormat(format),
    timezone: formData.scheduledTimeZone,
    duration: formData.meetingLength,
    endDate: DateTime.fromJSDate(scheduledEndDate).toFormat(format)
  };

  if (formData.meetingPassword) {
    opts.password = formData.meetingPassword;
  }

  let zoomMeetOpts = ZoomMeeting(opts);

  if (formData.backupTeacher)
    zoomMeetOpts.settings.alternative_hosts = formData.backupTeacher;

  const requests = [
    apiConfig.zoom.createZoomMeeting({
      body: zoomMeetOpts,
      zoomUserId: formData.zoomUserId
    })
  ];

  // DELETE A PREVIOUS MEETING BEFORE SCHEDULING NEW
  if (formData.zoomLink) {
    requests.push(deleteMeeting(formData.zoomMeetingId));
  }

  const [{ data }] = await Promise.all(requests);
  return extractMeetingData(data);
}

async function deleteMeeting(meetingId) {
  return await apiConfig.zoom.deleteZoomMeeting({ meetingId });
}

function extractMeetingData(data) {
  const { start_url, join_url, id, password, occurrences } = data;
  const response = {
    zoomLink: join_url,
    zoomStartLink: start_url,
    zoomMeetingId: id,
    occurrences: occurrences
  };

  if (password) {
    response.zoomMeetingPasscode = password;
  }

  return response;
}

/**
 * Find a `ZOOM_TIMEZONE` object matching the provided `zoneName`.
 * @param {string} zoneName Timezone name (e.g. `America/Los_Angeles`)
 */
export function findTZByText(zoneName) {
  // Helper: regex pattern match on 'zoneName'
  const matchTextOrName = ({ text, name }) => {
    const textPattern = new RegExp(`^${text}`);
    const namePattern = new RegExp(`^${name}`);
    return textPattern.test(zoneName) || namePattern.test(zoneName);
  };

  return ZOOM_TIMEZONES.find(matchTextOrName);
}

export function matchTimezone({ text }) {
  // Helper: regex pattern match on 'zoneName'
  const matchAbbrOrUTC = ({ abbr, utc }) => {
    const abbrPattern = new RegExp(abbr);
    return abbrPattern.test(text) || utc.includes(text);
  };

  return TIMEZONES.find(matchAbbrOrUTC);
}
