<template>
  <section class="tile-list route-content">
    <tile-list-item
      v-for="(tile, index) in tiles"
      :key="index"
      :title="tile.name"
      :description="tile.description"
      :action-text="tile.actionText"
      :background-image="tile.backgroundImage || null"
      :href="tile.to"
    />
  </section>
</template>

<script>
import TileListItem from "./TileListItem.vue";

export default {
  components: { TileListItem },

  name: "TileList",

  props: { tiles: Array }
};
</script>

<style lang="scss">
.tile-list {
  @include flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-bottom: $lg;
  width: $parent;

  .tile-list-item {
    flex-shrink: 0;
    flex-grow: 1;
    margin-bottom: $xxs;
    margin-right: $xxs;
    height: 32rem;
    max-width: 60rem;

    &.Events::after {
      background-image: url("../assets/events-header.jpg");
    }

    &.Classes::after {
      background-image: url("../assets/classes.jpg");
    }

    &.Users::after {
      background-image: url("../assets/Users_MVPAPP.jpeg");
    }

    &.Library::after {
      background-image: url("../assets/studio2.jpg");
    }

    &.One-on-One::after {
      background-image: url("../assets/One-on-One.jpeg");
    }
  }
}

@media screen and (max-width: 1400px) {
  .tile-list .tile-list-item {
    flex-grow: 1;
    min-width: 20rem;
  }
}

@media screen and (max-width: 900px) {
  .tile-list {
    .tile-list-item {
      margin-right: 0;
      height: 20rem;
      max-width: 24rem;
      width: $half;

      &::after {
        background-position: center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .tile-list {
    flex-direction: column;

    .tile-list-item {
      margin-bottom: $md;
      width: $parent;
    }
  }
}
</style>
