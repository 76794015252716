<template>
  <div id="loader" :class="`fade-in ${loadingMessage ? 'darker' : ''}`">
    <h4 v-if="loadingMessage">{{ loadingMessage }}</h4>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: "Loader",

  props: { loadingMessage: { type: String, default: "Loading..." } }
};
</script>

<style scoped lang="scss">
#loader {
  @include flex-center();
  background: $translucent;
  bottom: 0;
  flex-direction: column;
  left: 0;
  pointer-events: all;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;

  &.darker {
    background-color: #0000009d;
  }

  &::after {
    @include after-spinner();
  }

  h4 {
    @include bounce;
    animation-duration: 2500ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: white;
    text-transform: initial;
    font-weight: 300;
  }
}
</style>
